import React, {Fragment} from "react";
import CategorySwitcherElement
    from "../../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import CategorySwitcher from "../../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import ImageService from "../../../../Servicies/ImageService";
import VincoliMateriali from "./VincoliMateriali/VincoliMateriali";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {FotografoContextResponseModel} from "tici_commons";
import FotografoService from "../../../../Servicies/FotografoService";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../../Primary/LabelLoader/LabelLoader";
import VincoliModelli from "./VincoliMateriali/VincoliModelli";

export interface VincoliState {
    currentCategory: 'modelli' | 'materiali',
    isLoading: boolean,
    fotografiContext: FotografoContextResponseModel[]
}

export default class Vincoli extends React.Component<{}, VincoliState>{
    public constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            currentCategory: 'modelli',
            isLoading: true,
            fotografiContext: []
        }
    }

    public async componentDidMount() {
        const fotografiContext = await FotografoService.GetFotografoContext(true) as FotografoContextResponseModel[];
        this.setState({isLoading: false, fotografiContext});
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "modelli"}
                        elementIndex={0}
                        label={"Vincoli modelli"}
                        icon={ImageService.getImage('_3dIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "modelli"})}/>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "materiali"}
                        elementIndex={1}
                        label={"Vincoli materiali"}
                        icon={ImageService.getImage('rollIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "materiali"})}/>
                </CategorySwitcher>
                <SectionLayout size={"largeRelative"}>
                    <ResponsiveLabel alignment={"center"} content={"Gestisci i vincoli per ogni fotografo"} zeroShrink={true} type={"large"} uppercase={true} bold={true}/>
                    <IfContainer
                        condition={!this.state.isLoading}
                        elseComponent={<LabelLoader label={"Stiamo caricando le informazioni dei fotografi"}/>}>
                        <IfContainer
                            condition={this.state?.fotografiContext?.length > 0}
                            elseComponent={<ResponsiveLabel alignment={"center"} type={"medium"} content={"Nessun contesto fotografo recuperato"}/>}>
                            {this.state.currentCategory === 'modelli' && <VincoliModelli fotografiContext={this.state.fotografiContext}/>}
                            {this.state.currentCategory === 'materiali' && <VincoliMateriali fotografiContext={this.state.fotografiContext}/>}
                        </IfContainer>
                    </IfContainer>
                </SectionLayout>
            </Fragment>
        )
    }
}
