import React, {Fragment} from "react";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../../../Primary/LabelLoader/LabelLoader";
import VincoliService from "../../../../../Servicies/VincoliService";
import {FotografoContextResponseModel, VincoliModelliResponseModel} from "tici_commons";
import OverflowTag from "../../../../../Layout/OverflowTag/OverflowTag";
import {MaterialeLine, ModelloLine} from "../../../../../Widgets/SuperUser/SUComponents";
import Button from "../../../../../Core/Buttons/Button";
import ModalLayout from "../../../../../Layout/ModalLayout/ModalLayout";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import UserService from "../../../../../Servicies/UserService";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import TopLevelComponentStorage from "../../../../../Core/Arch/TopLevelComponentStorage";
import {ScrollZone, ScrollZoneElement, TextLine} from "../../../../../Widgets/Configuratore/CComponents";

export interface VincoliModelliProps{
    fotografiContext: FotografoContextResponseModel[]
}
export interface VincoliMaterialiState{
    isLoading: boolean,
    filtroModelli: string,
    modaleVisibile: boolean,
    vincoliModelli: VincoliModelliResponseModel[],
    vincoloSelezionato: VincoliModelliResponseModel,
    fotografoSelezionato: string
}

export default class VincoliModelli extends React.Component<VincoliModelliProps, VincoliMaterialiState>{
    public constructor(props: Readonly<VincoliModelliProps> | VincoliModelliProps) {
        super(props);
        this.state = {
            isLoading: true,
            filtroModelli: "",
            modaleVisibile: false,
            vincoliModelli: [],
            vincoloSelezionato: undefined,
            fotografoSelezionato: undefined
        }
    }

    public async componentDidMount() {
        await this._loadData();
    }

    private async _loadData(){
        this.setState({isLoading: true});
        const vincoliModelli = await VincoliService.GetVincoliModelli();
        this.setState({isLoading: false, vincoliModelli});
    }

    private async _aggiungiVincolo(){
        if(this.state.fotografoSelezionato && this.state.vincoloSelezionato){
            const idFotografo = parseInt(this.state.fotografoSelezionato.split(' ')[0]);
            const esitoInserimento = await VincoliService.InserisciVincoloModello(
                idFotografo,
                this.state.vincoloSelezionato.nomeModello
            );
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                esitoInserimento ? 'Vincolo inserito' : 'Vincolo non inserito',
                esitoInserimento ? 'Il vincolo é stato inserito correttamente' : 'Non é stato possibile inserire il vincolo'
            );
            this.setState({
                modaleVisibile: false,
                vincoloSelezionato: undefined,
                fotografoSelezionato: undefined,
            });

            if(esitoInserimento){
                await this._loadData();
            }
        }
    }

    private async _rimuoviVincolo(idFotografo: number, vincolo: VincoliModelliResponseModel){
        if(idFotografo !== undefined && vincolo){
            const esitoRimozione = await VincoliService.RimuoviVincoloModello(idFotografo, vincolo.nomeModello);
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                esitoRimozione ? 'Vincolo rimosso' : 'Vincolo non rimosso',
                esitoRimozione ? 'Il vincolo é stato rimosso correttamente' : 'Non é stato possibile rimuovere il vincolo'
            );
            if(esitoRimozione){
                await this._loadData();
            }
        }
    }

    private _listaVincoli(): VincoliModelliResponseModel[]{
        return this.state.vincoliModelli.filter(value => value.nomeModello.includes(this.state.filtroModelli));
    }

    private _apriModaleDiAggiunta(vincoloSelezionato: VincoliModelliResponseModel){
        this.setState({vincoloSelezionato, modaleVisibile: true});
    }

    private _ContenutoModaleAggiunta(){
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Seleziona il fotografo a cui vincolare il modello"} type={"medium"} alignment={"center"}/>
                <ElementSelect
                    label={"Seleziona"}
                    autocompleteMode={true}
                    blockMode={true}
                    elements={
                    this.props.fotografiContext.map(fotografo => ({
                        label: `${fotografo.identificativo} - ${fotografo.nome} ${fotografo.cognome} (${fotografo.email})`,
                        image: `${UserService.UserImageUrl(fotografo.email, fotografo.immagineProfilo)}`
                    }))}
                    value={this.state.fotografoSelezionato || ''}
                    onChange={v => this.setState({fotografoSelezionato: v})}/>
                <Responsive2Col>
                    <Button
                        content={"Conferma"}
                        type={"medium"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        onClick={() => this._aggiungiVincolo()}/>
                    <Button
                        content={"Annulla"}
                        type={"medium"}
                        alignment={"center"}
                        buttonType={"full-normal-negative"}
                        onClick={() => this.setState({modaleVisibile: false, vincoloSelezionato: undefined, fotografoSelezionato: undefined})}/>
                </Responsive2Col>
            </SectionLayout>
        )
    }

    private _ListaFotografiVincolati(vincolo: VincoliModelliResponseModel){
        let esito;

        if(vincolo.identificativiFotografo){
            const idFotografi = vincolo.identificativiFotografo.split(`~`);
            const immaginiFotografi = vincolo.immaginiFotografi.split(`~`);
            const nomiFotografi = vincolo.nomiFotografi.split(`~`);
            const emailFotografi = vincolo.emailFotografi.split(`~`);

            const listaTotale = idFotografi.map((id, index) => ({id: parseInt(id), nome: nomiFotografi[index], email: emailFotografi[index], immagine: immaginiFotografi[index]}));
            esito = (
                <ScrollZone>
                    {listaTotale.map(fotografo => (
                        <ScrollZoneElement
                            image={UserService.UserImageUrl(fotografo.email, fotografo.immagine)}
                            content={`${fotografo.id} - ${fotografo.nome} (${fotografo.email})`}
                            onDeleteClick={() => this._rimuoviVincolo(fotografo.id, vincolo)}/>
                    ))}
                </ScrollZone>
            )
        }

        return esito;
    }

    public render() {
        return (
            <Fragment>
                <ModalLayout isOpen={this.state.modaleVisibile} body={this._ContenutoModaleAggiunta()} disableMaxHeight={true}/>
                <SectionLayout size={"mediumRelative"}  addPadding={true} showBorder={true} lightBackground={true}>
                    <IfContainer
                        condition={!this.state.isLoading}
                        elseComponent={<LabelLoader label={'Caricamento dei vincoli'} />}>
                        <IfContainer
                            condition={this.state?.vincoliModelli?.length > 0}
                            elseComponent={<ResponsiveLabel alignment={"center"} type={"medium"} content={"Nessun vincolo recuperato"}/>}>
                            <TextLine
                                label={"Cerca per nome"}
                                value={this.state.filtroModelli}
                                onChange={v => this.setState({filtroModelli: v})}/>
                            <SectionLayout size={"largeRelative"}>
                                <OverflowTag/>
                                {
                                    this._listaVincoli().map(value => (
                                        <SectionLayout size={"largeRelative"} addPadding={true} lightBackground={true} showBorder={true}>
                                            <ModelloLine
                                                key={`Modello-${value.nomeModello}`}
                                                nomeModello={value.nomeModello}
                                                tipoModello={value.tipoModello}/>
                                            <SectionLayout size={"largeRelative"} addPadding={true} lightBackground={true} showBorder={true}>
                                                <IfContainer
                                                    condition={!value.identificativiFotografo}
                                                    elseComponent={this._ListaFotografiVincolati(value)}>
                                                    <ResponsiveLabel content={"Nessun vincolo sul modello"} type={"medium"} alignment={"center"}/>
                                                </IfContainer>
                                                <Button
                                                    content={"Aggiungi vincolo"}
                                                    type={"medium"}
                                                    buttonType={"full-normal-not-rounded"}
                                                    onClick={() => this._apriModaleDiAggiunta(value)}/>
                                            </SectionLayout>
                                        </SectionLayout>
                                    ))
                                }
                            </SectionLayout>
                        </IfContainer>
                    </IfContainer>
                </SectionLayout>
            </Fragment>
        );
    }
}
