import "./Ordine.scss";
import React, {Fragment} from "react";
import Config from "../../Static/Config";
import {
    DettaglioOrdineModel,
    InformazioniBaseModel,
    RilegaturaModel,
    CopertinaModel,
    CustodiaModel,
    AccessoriModel,
    FotolibroModel,
    InformazioniBaseInitializer,
    RilegaturaInitializer,
    CopertinaInitializer,
    AccessoriInitializer,
    CustodiaInitializer, UserContextResponseModel, TipoAmministrazioneType
} from "tici_commons";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Button from "../../Core/Buttons/Button";
import Responsive2Col from "../../Layout/Responsive2Col/Responsive2Col";
import {ChatContext, ChatContextState} from "../ChatOverlay/ChatContainer/ChatContainer";
import {Miniatura, OrdineLineRecap, OrdineLineTopSeparator} from "../../Widgets/Ordine/OrdineLineComponents";
import RendererData from "./RendererData";
import InformazioniBase from "../../Pages/SitoInterno/Configuratore/Sezioni/InformazioniBase/InformazioniBase";
import Rilegatura from "../../Pages/SitoInterno/Configuratore/Sezioni/Rilegatura/Rilegatura";
import Copertina from "../../Pages/SitoInterno/Configuratore/Sezioni/Copertina/Copertina";
import Custodia from "../../Pages/SitoInterno/Configuratore/Sezioni/Custodia/Custodia";
import Accessori from "../../Pages/SitoInterno/Configuratore/Sezioni/Accessori/Accessori";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import TicketService from "../../Servicies/TicketService";
import OrdineService from "../../Servicies/OrdineService";
import {RiepilogoBox} from "../../Pages/SitoInterno/Configuratore/Commons/RiepilogoDatoModel";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import CheckboxButton from "../../Core/Checkbox/CheckboxButton/CheckboxButton";
import PreviewStampaOrdine from "../PreviewStampaOrdine/PreviewStampaOrdine";
import LabelLoader from "../LabelLoader/LabelLoader";
import UserService from "../../Servicies/UserService";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import AmministrazioneService from "../../Servicies/AmministrazioneService";
import MiniaturaInteragibile from "../MiniaturaInteragibile/MiniaturaInteragibile";

export interface OrdineProps{
    ordine: DettaglioOrdineModel,
    vistaAmministrazione?: boolean,
    tipoAmministrazione?: TipoAmministrazioneType,
    updateOrdini?: () => void,
    materialeOrdineScaricato?: boolean,
    updateStatoDownload?:() => void
}

export interface OrdineState extends FotolibroModel{
    contestoUtente: UserContextResponseModel,
    stampeDisponibili: boolean,
    miniaturaCopertina: boolean,
    miniaturaCustodia: boolean,
    miniaturaIndex: boolean,
    sezioneSelezionata: 'Preview ordine' | 'Preview stampa' | 'Metriche sezione',
    isLoading: boolean
}

export default class Ordine extends React.Component<OrdineProps, OrdineState>{
     constructor(props: Readonly<OrdineProps> | OrdineProps) {
        super(props);
        this.state = {
            contestoUtente: undefined,
            informazioniBase: undefined,
            rilegatura: undefined,
            copertina: undefined,
            custodia: undefined,
            accessori: undefined,
            stampeDisponibili: false,
            miniaturaCopertina: false,
            miniaturaCustodia: false,
            miniaturaIndex: false,
            sezioneSelezionata: 'Preview ordine',
            isLoading: true
        };

        UserService.GetUserContext().then(contestoUtente => this.setState({contestoUtente}));
    }

    public componentDidMount() {
         this._getOrdineData();
    }

    /**
     * Recupera tutte le informazioni dell'ordine
     * @private
     */
    private async _getOrdineData(){
        if(this.props.ordine){
            this.setState({isLoading: true});
            this.setState({stampeDisponibili: await OrdineService.OrdineFileExist(this.props.ordine.id)});

            const ordineData = await OrdineService.RecuperaOrdineData(this.props.ordine.id);

            const informazioniBaseData = ordineData.find(data => data.tipoDatoOrdine === 'InformazioniBase');
            const copertinaData = ordineData.find(data => data.tipoDatoOrdine === 'Copertina');
            const custodiaData = ordineData.find(data => data.tipoDatoOrdine === 'Custodia');

            const informazioniBase = InformazioniBaseInitializer(informazioniBaseData?.data as unknown as InformazioniBaseModel);
            const rilegatura = RilegaturaInitializer(ordineData.find(data => data.tipoDatoOrdine === 'Rilegatura')?.data as unknown as RilegaturaModel);
            const copertina = CopertinaInitializer(copertinaData?.data as unknown as CopertinaModel);
            const custodia = CustodiaInitializer(custodiaData?.data as unknown as CustodiaModel);
            const accessori = AccessoriInitializer(ordineData.find(data => data.tipoDatoOrdine === 'Accessori')?.data as unknown as AccessoriModel);

            const miniaturaIndex = informazioniBase && informazioniBaseData.metaData && (informazioniBaseData.metaData as any).miniatura;
            const miniaturaCopertina = copertinaData && copertinaData.metaData && (copertinaData.metaData as any).miniatura;
            const miniaturaCustodia = custodiaData && custodiaData.metaData && (custodiaData.metaData as any).miniatura;

            this.setState({
                informazioniBase,
                rilegatura,
                copertina,
                custodia,
                accessori,
                miniaturaCopertina,
                miniaturaCustodia,
                miniaturaIndex,
                isLoading: false
            })
        }
    }

    /**
     * Cambia lo stato di conferma per un ordine
     * @private
     */
    private async _cambiaStatoConferma(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Stiamo inviando il cambiamento sul server");
        const esito = await TicketService.CambiaStatoConferma(this.props.ordine.idTicket, this.props.ordine.statoConferma === 0);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? "Conferma" : "Errore",
            esito ? "Modifica avvenuta" : "Modifica non avvenuta",
            "SingleButton",
            () => this.props.updateOrdini && this.props.updateOrdini()
        );
    }

    /**
     * Cambia lo stato della segnalazione per un ordine
     * @private
     */
    private async _cambiaStatoSegnalazione(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Stiamo inviando il cambiamento sul server");
        const esito = await TicketService.CambiaStatoSegnalazione(this.props.ordine.idTicket, this.props.ordine.statoErrore === 0);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? "Conferma" : "Errore",
            esito ? "Modifica avvenuta" : "Modifica non avvenuta",
            "SingleButton",
            () => this.props.updateOrdini && this.props.updateOrdini()
        );
    }

    /**
     * Scarica le immagini del progetto
     * @private
     */
    private async _downloadImmagini(){

        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Preparazione", "Stiamo recuperando i file per quest'ordine");
        const fileProgettoPath = await OrdineService.OrdineFilePath(this.props.ordine.id);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        let hasError = true;
        if(fileProgettoPath){
            hasError = false;
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Download file",
                "Tutto é pronto per il download",
                "SingleButton",
                async () => {
                    const a = document.createElement('a');
                    const nomeDownload = `${fileProgettoPath.split('.')[0]}-${this.props.ordine.nomeFotografo}-${this.props.ordine.cognomeFotografo}-${this.props.ordine.emailFotografo}.${fileProgettoPath.split('.')[1]}`;
                    a.href = `${Config.PublicPath}/ProjectData/${fileProgettoPath}/${nomeDownload}`;
                    a.download = nomeDownload;
                    a.click();

                    if(!this.props.materialeOrdineScaricato){
                        const esitoImpostazione = await AmministrazioneService.ImpostaStatoScaricatoMaterialeOrdine(this.props.ordine.id);
                        if(esitoImpostazione)
                            this.props.updateStatoDownload && this.props.updateStatoDownload();
                    }
                }
            )
        }

        if(hasError){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Errore recupero file",
                "Non è stato possibile recuperare i file dell'ordine",
            );
        }
    }

    /**
     * Scarica l'index
     * @private
     */
    private async _downloadIndex(){
        const image = new Image();
        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            const a = document.createElement('a');
            a.href = canvas.toDataURL();
            a.download = 'index.png';
            a.click();
        });
        image.crossOrigin="anonymous"
        image.src = OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'indice');
    }

    /**
     * Cancella l'ordine
     * @private
     */
    private async _cancellaOrdine(){
        if(this.props.ordine.statoConferma === 0){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Sicuro?",
                "Sicuro di voler eliminare quest'ordine? Le sue informazioni non saranno più recuperabili",
                "DoubleButton",
                async () => {
                    const esito = await OrdineService.EliminaOrdine(this.props.ordine.id);
                    this.props.updateOrdini && this.props.updateOrdini();
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                        `Eliminazione ${esito ? 'completata' : 'non completata'}`,
                        esito ? "L'eliminazione dell'ordine è avvenuta" : "L'eliminazione dell'ordine è fallita",
                    )
                },
                () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
            )
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Ordine già confermato",
                "Quest'ordine risulta già confermato e non può essere eliminato",
            );
        }

    }

    private _sezioneSelezionePreview(){
        return (
            <OrdineLineTopSeparator>
                <SentenceLayout alignment={"left"}>
                    <CheckboxButton
                        content={"Preview Ordine"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}
                        checked={this.state.sezioneSelezionata === 'Preview ordine'}
                        onChange={() => this.setState({sezioneSelezionata: 'Preview ordine'})}/>
                    <CheckboxButton
                        content={"Preview Stampa"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}
                        checked={this.state.sezioneSelezionata === 'Preview stampa'}
                        onChange={() => this.setState({sezioneSelezionata: 'Preview stampa'})}/>
                    <CheckboxButton
                        content={"Metriche di sezione"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}
                        checked={this.state.sezioneSelezionata === 'Metriche sezione'}
                        onChange={() => this.setState({sezioneSelezionata: 'Metriche sezione'})}/>
                </SentenceLayout>
            </OrdineLineTopSeparator>
        )
    }

    private _sezioneStandarOrdine(){
        return (
            <OrdineLineTopSeparator label={"Informazioni Generali"} backgroundColor={"rgb(233,255,247)"}>
                <OrdineLineRecap label={"ID ordine"} content={this.props.ordine.id}/>
                <OrdineLineRecap label={"Studio fotografico"} content={this.props.ordine.studioFotografo}/>
                <OrdineLineRecap label={"Fotografo"} content={`${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}/>
                <OrdineLineRecap label={"Email fotografo"} content={`${this.props.ordine.emailFotografo}`}/>
                <OrdineLineRecap label={"Telefono fotografo"} content={`${this.props.ordine.telefonoFotografo}`}/>
                <OrdineLineRecap label={"Data ordine"} content={`${this.props.ordine.dataCreazione.split('-').reverse().join('/')}`}/>
                <IfContainer condition={this.state.contestoUtente?.tipoUtente === 'amministrazione'}>
                    <OrdineLineRecap label={"Zona fotografo"} content={`${this.props.ordine.locazioneFotografo}`}/>
                </IfContainer>
            </OrdineLineTopSeparator>
        )
    }

    private _informazioniOrdineTopLevelView(){
        return (
            <SectionLayout size={"largeRelative"}>
                {this._sezioneSelezionePreview()}
                <IfContainer
                    condition={this.state.sezioneSelezionata === 'Preview ordine'}>
                    {this._informazioniOrdine()}
                </IfContainer>
                <IfContainer
                    condition={this.state.sezioneSelezionata === 'Preview stampa'}>
                    {this._previewStampaOrdine()}
                </IfContainer>
                <IfContainer
                    condition={this.state.sezioneSelezionata === 'Metriche sezione'}>
                    {this._metricheSezione()}
                </IfContainer>
            </SectionLayout>
        )
    }

    private _informazioniOrdine(){
        return (
            <Fragment>
                {this._sezioneStandarOrdine()}
                {this.state.informazioniBase &&
                    <RendererData backgroundColor={'rgb(235,255,230)'} data={ new InformazioniBase(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.rilegatura && this.state.informazioniBase.tipoFotolibro !== 'accessori' &&
                    <RendererData backgroundColor={'rgb(234,237,255)'} data={ new Rilegatura(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.copertina && this.state.informazioniBase.tipoFotolibro !== 'accessori' &&
                    <RendererData backgroundColor={'rgb(255,229,229)'} data={ new Copertina(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.custodia &&
                    <RendererData backgroundColor={'rgb(255,241,247)'} data={ new Custodia(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.accessori &&
                    (new Accessori(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox[])
                        .map(accessorio => (<RendererData backgroundColor={'rgb(255,243,225)'} data={accessorio}/>))}

                {this.props.vistaAmministrazione && (this.state.copertina || this.state.custodia) && this._sezioneModelli3D()}

                <IfContainer condition={this.state.miniaturaCustodia || this.state.miniaturaCopertina || this.state.informazioniBase?.stampaFlag || this.state.miniaturaIndex}>
                    <OrdineLineTopSeparator label={"Miniature"}>
                        <Responsive2Col>
                            {this.state.miniaturaCopertina && <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'copertina')}/>}
                            {this.state.miniaturaCustodia && <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'custodia')}/>}
                        </Responsive2Col>
                        <IfContainer condition={(this.state.informazioniBase && this.state.informazioniBase.stampaFlag) || this.state.miniaturaIndex}>
                            <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'indice')}/>
                        </IfContainer>
                    </OrdineLineTopSeparator>
                </IfContainer>
            </Fragment>
        )
    }

    private _sezioneModelli3D(){
        const copertina = (
            this.state.informazioniBase &&
            this.state.copertina?.modelloCopertina &&
            <OrdineLineTopSeparator label={"Copertina"}>
                <MiniaturaInteragibile
                    nomeModello={this.state.copertina.modelloCopertina}
                    idOrdine={this.props.ordine.id}
                    nomeManager={'copertina'}
                    aspectRatioModello={this.state.informazioniBase.formatoAlbum}
                    modalitaAlternativa={this.state.informazioniBase.orientamentoAlbum.toLowerCase() === 'panoramico'}
                    datiMiniatura={this.state.copertina.sezioniCopertina}/>
            </OrdineLineTopSeparator>
        );

        const copertinaRidotto = (
            this.state.informazioniBase &&
            this.state.copertina?.modelloCopertina &&
            this.state.informazioniBase.albumRidottoFlag &&
            <OrdineLineTopSeparator label={"Copertina ridotto"}>
                <MiniaturaInteragibile
                    nomeModello={"1000"}
                    idOrdine={this.props.ordine.id}
                    nomeManager={'copertinaridotto'}
                    aspectRatioModello={this.state.informazioniBase.formatoAlbum}
                    modalitaAlternativa={this.state.informazioniBase.orientamentoAlbum.toLowerCase() === 'panoramico'}
                    datiMiniatura={this.state.copertina.sezioniCopertinaAlbumRidotto}/>
            </OrdineLineTopSeparator>
        );

        const custodia = (
            this.state.informazioniBase &&
            this.state.informazioniBase.custodiaFlag &&
            this.state.custodia?.modelloCustodia &&
            <OrdineLineTopSeparator label={"Custodia"}>
                <MiniaturaInteragibile
                    nomeModello={this.state.custodia.modelloCustodia}
                    idOrdine={this.props.ordine.id}
                    nomeManager={'custodia'}
                    aspectRatioModello={this.state.informazioniBase.formatoAlbum}
                    datiMiniatura={this.state.custodia.sezioniCustodia}/>
            </OrdineLineTopSeparator>
        );

        const custodiaRidotto = (
            this.state.informazioniBase &&
            this.state.informazioniBase.custodiaFlag &&
            this.state.informazioniBase.albumRidottoFlag &&
            this.state.custodia?.modelloCustodia &&
            this.state.custodia.cofanettoPiccoloFlag &&
            <OrdineLineTopSeparator label={"Custodia ridotto"}>
                <MiniaturaInteragibile
                    nomeModello={this.state.custodia.modelloCofanettoPiccolo}
                    idOrdine={this.props.ordine.id}
                    nomeManager={'custodiaridotto'}
                    aspectRatioModello={this.state.informazioniBase.formatoAlbum}
                    datiMiniatura={this.state.custodia.sezioniCustodia}/>
            </OrdineLineTopSeparator>
        );

        return (
            <IfContainer condition={!!copertina || !!copertinaRidotto || !!custodia || !!custodiaRidotto}>
                <OrdineLineTopSeparator label={"Modelli 3D"}>
                    <Responsive2Col>
                        { copertina }
                        { copertinaRidotto }
                        { custodia }
                        { custodiaRidotto }
                    </Responsive2Col>
                </OrdineLineTopSeparator>
            </IfContainer>
        )
    }

    private _previewStampaOrdine(){
        return (
            <PreviewStampaOrdine idOrdine={this.props.ordine.id}>
                {this._informazioniOrdine()}
            </PreviewStampaOrdine>
        )
    }

    private _metricheSezione(){
        const arrayMetriche = [
            ...(this.state?.copertina?.sezioniCopertina || []),
            ...(this.state?.copertina?.sezioniCopertinaAlbumRidotto || []),
            ...(this.state?.custodia?.sezioniCustodia || []),
            ...(this.state?.custodia?.sezioniCustodiaCofanettoPiccolo || [])
        ].map(sezione => {
            let informazione = undefined;

            const data = sezione[1];
            if(data?.informazioneImmagine?.nomeMetricheImmagine) {
                informazione = [data?.informazioneImmagine?.nomeMetricheImmagine];
                if(data?.informazioneImmagine?.nomePosizionamentoImmagine)
                    informazione.push(data?.informazioneImmagine?.nomePosizionamentoImmagine);
            }
            if(data?.informazioneUv?.nomeMetricheUv) {
                informazione = [data?.informazioneUv?.nomeMetricheUv];
                if(data?.informazioneUv.nomePosizionamentoUv)
                    informazione.push(data?.informazioneUv.nomePosizionamentoUv);
            }

            if(informazione && !!informazione[0]){
                const parti = informazione[0].split('~');
                const sezione = parti[2];
                const categoria = parti[1];
                informazione.push(sezione, categoria);
            }

            return informazione
        }).filter(sezione => !!sezione);


        return (
            <IfContainer
                condition={arrayMetriche.length > 0}
                elseComponent={
                    <Fragment>
                        <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
                        <ResponsiveLabel
                            content={"Nessuna metrica presente"}
                            alignment={"center"}
                            type={"xmedium"}/>
                        <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
                    </Fragment>
                }>
                {
                    arrayMetriche.map(metrica => (
                        <Responsive2Col>
                            <Miniatura
                                title={`Maschera posizione ${metrica[3]}: ${metrica[2]}`}
                                downloadImage={true}
                                headDownload={`${this.props.ordine.id} ${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}
                                url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, metrica[0])}/>
                            <Miniatura
                                title={`Reference posizione ${metrica[3]}: ${metrica[2]}`}
                                downloadImage={true}
                                headDownload={`${this.props.ordine.id} ${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}
                                url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, metrica[1])}/>
                        </Responsive2Col>

                    ))
                }
            </IfContainer>
        )
    }

    private _menuAmministrazione(chat: ChatContextState){
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer condition={this.state.stampeDisponibili && !this.menuAmministrazioneRidotto}>
                    <Button
                        content={"Scarica stampe"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal-not-rounded"}
                        onClick={() => this._downloadImmagini()}/>
                </IfContainer>
                <IfContainer condition={!this.menuAmministrazioneRidotto}>
                    <Responsive2Col>
                        <Button
                            content={`${this.props.ordine.statoConferma === 0 ? 'Conferma ordine' : 'Annulla conferma ordine'}`}
                            type={"large"}
                            alignment={"center"}
                            buttonType={"full-normal-not-rounded"}
                            onClick={() => {
                                this._cambiaStatoConferma();
                            }}/>
                        <Button
                            content={`${this.props.ordine.statoErrore === 0 ? 'Segnala errore' : 'Risolvi segnalazione'}`}
                            type={"large"}
                            alignment={"center"}
                            buttonType={"full-normal-not-rounded"}
                            onClick={() => {
                                this._cambiaStatoSegnalazione();
                            }}/>
                    </Responsive2Col>
                </IfContainer>
                <Button
                    content={"Apri chat interna ordine"}
                    type={"large"}
                    alignment={"center"}
                    buttonType={"full-normal-not-rounded"}
                    onClick={() => chat.openChat(this.props.ordine.id, `${this.props.ordine.id} - ${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo} - ${this.props.ordine.nomeOrdine} - INTERNA`, true)}/>
            </SectionLayout>
        )
    }

    private _menuFotografo(){
        return (
            <SectionLayout size={"largeRelative"} center={true}>
                <Button
                    content={"Elimina l'ordine"}
                    type={"large"}
                    alignment={"center"}
                    buttonType={"full-normal-not-rounded"}
                    onClick={() => {
                        this._cancellaOrdine()
                    }}/>
            </SectionLayout>
        )
    }

    private _menuComune(chat: ChatContextState){
        return (
            <SectionLayout size={"largeRelative"} center={true}>
                <Button
                    content={"Apri chat ordine"}
                    type={"large"}
                    alignment={"center"}
                    buttonType={"full-normal-not-rounded"}
                    onClick={() => chat.openChat(this.props.ordine.id, `${this.props.ordine.id} - ${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo} - ${this.props.ordine.nomeOrdine}`)}/>
                <IfContainer condition={this.state.informazioniBase && this.state.informazioniBase.stampaFlag}>
                    <Button
                        content={"Scarica l'index"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal-not-rounded"}
                        onClick={() => this._downloadIndex()}/>
                </IfContainer>
            </SectionLayout>
        )
    }

    public render() {
        return (
            <div className={"OrdineContainer"}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <OrdineLineTopSeparator>
                            <LabelLoader label={'Caricamento dell\'ordine'}/>
                        </OrdineLineTopSeparator>
                    }>
                    {this._informazioniOrdineTopLevelView()}
                    <SectionLayout size={"largeRelative"} addPadding={true} lightBackground={true} showBorder={true}>
                        <ChatContext.Consumer>{
                            chat => (
                                <Fragment>
                                    {this.props.vistaAmministrazione && this._menuAmministrazione(chat)}
                                    {!this.props.vistaAmministrazione && this._menuFotografo()}
                                    {this._menuComune(chat)}
                                </Fragment>
                            )}
                        </ChatContext.Consumer>

                    </SectionLayout>
                </IfContainer>
            </div>
        );
    }

    //beam

    public get menuAmministrazioneRidotto(): boolean{
        return (
            this.props?.tipoAmministrazione === 'Reparto copertine' ||
            this.props?.tipoAmministrazione === 'Reparto custodie'
        )
    }
}
