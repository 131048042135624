import "./CComponents.scss";
import React from "react";
import xIcon from "../../Media/Images/Icons/xIcon.png";
import redAlertIcon from "../../Media/Images/Icons/redAlertIcon.png";
import yellowAlertIcon from "../../Media/Images/Icons/yellowAlertIcon.png";
import DefaultInput, {DefaultInputProps} from "../../Core/DefaultInput/DefaultInput";
import TwoSpaceLine from "../../Layout/TwoSpaceLine/TwoSpaceLine";
import CheckboxProps from "../../Core/Checkbox/CheckboxProps";
import Checkbox from "../../Core/Checkbox/Checkbox/Checkbox";
import Switch from "../../Core/Checkbox/Switch/Switch";
import ElementSelect, {ElementSelectProps} from "../../Primary/Selects/ElementSelect/ElementSelect";
import ResponsiveLabel, {ResponsiveLabelType} from "../../Core/ResponsiveLabel/ResponsiveLabel";
import {StorageData, StorageDataRenderer} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import IfContainer from "../../Layout/IfContainer/IfContainer";

export interface TextLineProps extends DefaultInputProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    labelType?: ResponsiveLabelType
}
export function TextLine(props: TextLineProps){
    return(
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            labelType={props.labelType}>
            <DefaultInput {...props}/>
        </TwoSpaceLine>
    );
}

export interface CheckLineProps extends CheckboxProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    reduce?: boolean,
    labelType?: ResponsiveLabelType,
    checkType: "checkbox" | "switch",
}
export function CheckLine(props: CheckLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            wrapOnMobile={false}
            reduce={props.reduce}
            labelType={props.labelType}>
            {
                props.checkType === "checkbox" &&
                <Checkbox
                    disabled={props.disabled}
                    onChange={props.onChange}
                    checked={props.checked}/>
            }
            {
                props.checkType === "switch" &&
                <Switch
                    disabled={props.disabled}
                    onChange={props.onChange}
                    checked={props.checked}/>
            }
        </TwoSpaceLine>
    )
}

export interface SelectLineProps extends Omit<ElementSelectProps, "label" | "onFileUpload">{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    labelType?: ResponsiveLabelType,
    placeHolder: string,
    autocompleteMode?: boolean
}
export function SelectLine(props: SelectLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            wrapOnMobile={true}
            labelType={props.labelType}>
            <ElementSelect
                label={props.placeHolder}
                elements={props.elements}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
                maxHeight={props.maxHeight}
                autocompleteMode={props.autocompleteMode}
                blockMode={props.blockMode}/>
        </TwoSpaceLine>
    );
}

export interface ImageVisualizerProps{
    imageData?: string;
    storageData?: StorageData;
    onDelete?: () => void;
}
export function ImageVisualizer(props: ImageVisualizerProps){
    return (
        <TwoSpaceLine label={""}>
            <div className={"ImageVisualizer"}>
                {props.imageData && <img alt={"imageVisualizer"} className={"image"} src={props.imageData}/>}
                {props.storageData && <StorageDataRenderer storageData={props.storageData} className={"image"}/>}
                <div onClick={() => {props.onDelete && props.onDelete()}} className={"deleteButton"}/>
            </div>
        </TwoSpaceLine>
    )
}

export interface ImmagineMiniaturaProps{
    immagineData?: string;
}

export function ImmagineMiniatura (props: ImmagineMiniaturaProps){
    return (
        props.immagineData &&
        <img alt={"CopertinaImage"} style={{width: '100%'}} src={props.immagineData}/>
    )
}

export function ScrollZone(props: {children?: any}){
    return (
        <div className={"ScrollZone"}>
            {props.children}
        </div>
    )
}

export interface ScrollZoneElementProps{
    image?: string,
    content: string,
    onDeleteClick?: () => void
}
export function ScrollZoneElement(props: ScrollZoneElementProps){
    return (
        <span className={"ScrollZoneElement"}>
            <IfContainer condition={!!props.image}>
                <img alt={""} className={"image"} src={props.image}/>
            </IfContainer>
            <ResponsiveLabel content={props.content} type={"medium"} alignment={"left"}/>
            <img onClick={() => {
                props.onDeleteClick && props.onDeleteClick();
            }} className={"deleteButton"} src={xIcon} alt={"xIcon"}/>
        </span>
    )
}

export interface RecapElementProps{
    value: string;
    messageType: "recap" | "error" | "warning";
}
export function RecapElement(props: RecapElementProps){
    const labelType = props.messageType === "error" ? "errorLabel" : (props.messageType === "warning" ? "warningLabel" : "recapLabel")
    return (
        <span className={"RecapElement"}>
            {props.messageType === "error" && <img alt={"alertIcon"} className={"image"} src={redAlertIcon}/>}
            {props.messageType === "warning" && <img alt={"alertIcon"} className={"image"} src={yellowAlertIcon}/>}
            <ResponsiveLabel content={props.value} type={"medium"} alignment={"left"} labelType={labelType} asHTML={true}/>
        </span>
    )
}
