import "./SUComponents.scss";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Config from "../../Static/Config";
import ImageService from "../../Servicies/ImageService";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import {TipoModelloType} from "../../../../tici_commons";

export interface MaterialeLineProps{
    nomeMateriale: string,
    famigliaMateriale: string,
    lussuriaMateriale: string,
    spessoreMateriale: string,
    nomeMaterialeImage: string,
    ammetteUv: number,
    onDelete?: () =>  void
}
export function MaterialeLine(props: MaterialeLineProps){
    return (
        <span className={"MaterialeLine"}>
            <img className={"materialImage"} src={`${Config.PublicPath}/${props.nomeMaterialeImage}`} alt={"Materiale"}/>
            <ResponsiveLabel content={`${props.lussuriaMateriale}: ${props.famigliaMateriale} ${props.nomeMateriale} ${props.spessoreMateriale} ${props.ammetteUv === 1 ? "Ammette UV" : "Non ammette UV"}`} type={"medium"} alignment={"center"}/>
            <IfContainer condition={!!props.onDelete}>
                <img className={"deleteButton"} src={ImageService.getImage('xIcon')} alt={"xIcon"} onClick={() => {
                    props.onDelete && props.onDelete();
                }}/>
            </IfContainer>
        </span>
    )
}

export interface ModelloLineProps{
    nomeModello: string,
    tipoModello: TipoModelloType,
    onDelete?: () =>  void
}
export function ModelloLine(props: ModelloLineProps){
    const mappaPathImmagine = (): string => {
        let pathImmagine = Config.NoMapping;

        if(props.tipoModello === 'copertina'){
            pathImmagine += '/Copertine';
        }else if(props.tipoModello === 'custodia'){
            pathImmagine += '/Cofanetti';
        }

        return pathImmagine + '/' + props.nomeModello + '.jpg';
    }

    const mostraImmagine = (): boolean => {
        return props.tipoModello === 'copertina' || props.tipoModello === 'custodia';
    }

    return (
        <span className={"ModelloLine"}>
            {
                mostraImmagine() &&
                <img className={"modelImage"} src={mappaPathImmagine()} alt={"Modello"}/>
            }
            <ResponsiveLabel content={`Modello: ${props.nomeModello}`} type={"medium"} alignment={"center"}/>
            <IfContainer condition={!!props.onDelete}>
                <img className={"deleteButton"} src={ImageService.getImage('xIcon')} alt={"xIcon"} onClick={() => {
                    props.onDelete && props.onDelete();
                }}/>
            </IfContainer>
        </span>
    )
}
