import AutoRefreshComponent from "../../../../Core/Arch/AutoRefreshComponent";
import {CheckLine, ImageVisualizer, SelectLine, TextLine} from "../../../../Widgets/Configuratore/CComponents";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import {Lussurie, MaterialiInterface, SpessoreMateriale} from "tici_commons";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import {MaterialeLine} from "../../../../Widgets/SuperUser/SUComponents";
import CategorySwitcher from "../../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import CategorySwitcherElement
    from "../../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import React, {Fragment} from "react";
import Button from "../../../../Core/Buttons/Button";
import DataService from "../../../../Servicies/DataService";
import TopLevelComponentStorage from "../../../../Core/Arch/TopLevelComponentStorage";
import SuperUserService from "../../../../Servicies/SuperUserService";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import OverflowTag from "../../../../Layout/OverflowTag/OverflowTag";
import ImageService from "../../../../Servicies/ImageService";

export interface GestoreMaterialiState{
    currentCategory: "aggiunta" | "materiali",

    nomeMateriale: string,
    nomeMaterialeImage: string,
    famigliaMateriale: string,
    lussuriaMateriale: string,
    spessoreMateriale: SpessoreMateriale,
    ammetteUv: boolean,

    filtroMateriali: string,
    materiali: MaterialiInterface[]
}

export default class GestoreMateriali extends AutoRefreshComponent<{}, GestoreMaterialiState>{
    private _immagineMateriale: string = "";    //Immagine base64 del materiale

    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.Delay = 10000;
        this.state = {
            currentCategory: "aggiunta",

            nomeMateriale: "",
            nomeMaterialeImage: "",
            famigliaMateriale: "",
            lussuriaMateriale: "",
            spessoreMateriale: "sottile",
            ammetteUv: false,

            filtroMateriali: "",
            materiali: []
        }
    }

    public cycleMateriali = async () => {
        this.setState({materiali: await DataService.ListaMateriali()});
    }

    /**
     * Restituisce la lista dei materiali
     * @private
     */
    private _listaMateriali(): MaterialiInterface[]{
        return this.state.materiali.filter(value => value.nomeMateriale.toLowerCase().includes(this.state.filtroMateriali.toLowerCase())).sort((a, b) => {
            return Lussurie.indexOf(a.lussuriaMateriale) - Lussurie.indexOf(b.lussuriaMateriale);
        });
    }

    /**
     * Restituisce la lista delle famiglie
     * @private
     */
    private _listaFamiglie(): string[]{
        const esito: string[] = [];

        for(const materiale of this.state.materiali){
            if(!esito.includes(materiale.famigliaMateriale))
                esito.push(materiale.famigliaMateriale);
        }

        return esito;
    }

    /**
     * Effettua un'eliminazione del materiale
     * @param nomeMateriale Nome del materiale da eliminare
     * @param famigliaMateriale Famiglia del materiale da eliminare
     * @param lussuriaMateriale Lussuria del materiale da eliminare
     * @private
     */
    private _eliminaMateriale(nomeMateriale: string, famigliaMateriale: string, lussuriaMateriale: string){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Eliminazione materiale",
            "Eliminando il materiale questo sarà eliminato definitivamente dal configuratore3D",
            "DoubleButton",
            async () => {
                const esito = SuperUserService.EliminaMateriale(nomeMateriale, famigliaMateriale, lussuriaMateriale);
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Eliminazione completata" : "Eliminazione non completata",
                    esito ? "Abbiamo eliminato il materiale" : "Non è stato possibile eliminare il materiale"
                );
            },
            () => {
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
            }
        )
    }

    /**
     * Effettua l'aggiunta sul database del nuovo materiale
     * @private
     */
    private async _aggiungiMateriale(){
        if(this._canSubmit()){
            TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Caricamento del materiale");
            const esito = await SuperUserService.AggiungiMateriale(
                this.state.nomeMateriale,
                this.state.famigliaMateriale,
                this.state.lussuriaMateriale,
                this.state.spessoreMateriale,
                this.state.nomeMaterialeImage,
                this._immagineMateriale,
                this.state.ammetteUv
            )
            TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();

            if(esito){
                this._immagineMateriale = "";
                this.setState({
                    nomeMateriale: "",
                    famigliaMateriale: "",
                    lussuriaMateriale: "",
                    spessoreMateriale: "sottile",
                    nomeMaterialeImage: "",
                    ammetteUv: false
                });
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Materiale caricato",
                    "Il materiale è stato caricato correttamente"
                );
            }else{
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Materiale non caricato",
                    "Il materiale non è stato caricato"
                );
            }
        }
    }

    /**
     * Controlla se l'utente può o meno creare il materiale
     * @private
     */
    private _canSubmit(): boolean{
        let esito = true;

        esito &&= this.state.nomeMateriale.length !== 0;
        esito &&= this.state.famigliaMateriale.length !== 0;
        esito &&= this.state.lussuriaMateriale.length !== 0;
        esito &&= this.state.nomeMaterialeImage.length !== 0;
        esito &&= this.state.spessoreMateriale.length !== 0;

        return esito;
    }

    private _menuAggiunta(){
        return (
            <Fragment>
                <IfContainer condition={this.state.nomeMaterialeImage.length !== 0}>
                    <ImageVisualizer
                        imageData={this._immagineMateriale}
                        onDelete={() => {
                            this._immagineMateriale = "";
                            this.setState({nomeMaterialeImage: ""});
                        }}/>
                </IfContainer>
                <TextLine
                    label={"Immagine materiale"}
                    type={"file"}
                    value={this.state.nomeMaterialeImage}
                    onFileUpload={(fileName, fileType, file, base64Encoding) => {
                        if("png:jpg:jpeg".includes(fileType.toLowerCase())){
                            this._immagineMateriale = base64Encoding;
                            this.setState({nomeMaterialeImage: fileName});
                        }
                    }}
                    showError={this.state.nomeMaterialeImage.length === 0}
                    errorMessage={"L'immagine del materiale non può essere vuota"}/>
                <TextLine
                    label={"Nome materiale"}
                    value={this.state.nomeMateriale}
                    onChange={v => this.setState({nomeMateriale: v})}
                    showError={this.state.nomeMateriale.length === 0}
                    errorMessage={"Il nome del materiale non può essere vuoto"}/>
                <SelectLine
                    label={"Famiglia materiale"}
                    placeHolder={"famiglia"}
                    elements={this._listaFamiglie().map(value => ({label: value}))}
                    value={this.state.famigliaMateriale}
                    onChange={v => this.setState({famigliaMateriale: v})}
                    showError={this.state.famigliaMateriale.length === 0}
                    errorMessage={"La famiglia del materiale non può essere vuota"}
                    autocompleteMode={true}/>
                <SelectLine
                    label={"Prestigio materiale"}
                    placeHolder={"Seleziona"}
                    elements={Lussurie.map(value => ({label: value}))}
                    value={this.state.lussuriaMateriale}
                    onChange={v => this.setState({lussuriaMateriale: v})}
                    showError={this.state.lussuriaMateriale.length === 0}
                    errorMessage={"La lussuria del materiale non può essere vuota"}/>
                <SelectLine
                    label={"Spessore materiale"}
                    placeHolder={"Seleziona"}
                    elements={[{label: "Spesso"}, {label: "Sottile"}]}
                    value={this.state.spessoreMateriale}
                    onChange={v => this.setState({spessoreMateriale: v.toLowerCase() as SpessoreMateriale})}
                    showError={this.state.spessoreMateriale.length === 0}
                    errorMessage={"Lo spessore del materiale non può essere vuoto"}/>
                <CheckLine
                    label={"Ammette uv"}
                    checkType={"checkbox"}
                    checked={this.state.ammetteUv}
                    onChange={v => this.setState({ammetteUv: v})}
                    reduce={true}/>
                <Button
                    content={"Aggiungi"}
                    type={"large"}
                    buttonType={"full-normal"}
                    disabled={!this._canSubmit()}
                    onClick={() => this._aggiungiMateriale()}/>
            </Fragment>
        );
    }

    private _menuMateriali(){
        return (
            <Fragment>
                <TextLine
                    label={"Cerca per nome"}
                    value={this.state.filtroMateriali}
                    onChange={v => this.setState({filtroMateriali: v})}/>
                <IfContainer condition={this._listaMateriali().length > 0}>
                    <SectionLayout size={"largeRelative"}>
                        <OverflowTag/>
                        {
                            this._listaMateriali().map(value => (
                                <MaterialeLine
                                    key={`Materiale-${value.nomeMateriale}-${value.famigliaMateriale}-${value.lussuriaMateriale}`}
                                    nomeMateriale={value.nomeMateriale}
                                    lussuriaMateriale={value.lussuriaMateriale}
                                    famigliaMateriale={value.famigliaMateriale}
                                    spessoreMateriale={value.spessoreMateriale}
                                    nomeMaterialeImage={value.pathImmagine}
                                    ammetteUv={value.ammetteUv}
                                    onDelete={() => {
                                        this._eliminaMateriale(value.nomeMateriale, value.famigliaMateriale, value.lussuriaMateriale);
                                    }}/>
                            ))
                        }
                    </SectionLayout>
                </IfContainer>
            </Fragment>
        )
    }

    public render(){
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "aggiunta"}
                        elementIndex={0}
                        label={"Aggiungi"}
                        icon={ImageService.getImage('plusIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "aggiunta"})}/>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "materiali"}
                        elementIndex={1}
                        label={"Materiali"}
                        icon={ImageService.getImage('rollIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "materiali"})}/>
                </CategorySwitcher>
                <SectionLayout size={"largeRelative"}>
                    <ResponsiveLabel alignment={"center"} content={"Gestisci i materiali del configuratore"} type={"large"} uppercase={true} bold={true}/>
                    <SectionLayout
                        size={"mediumRelative"}
                        center={true}
                        addPadding={true}
                        lightBackground={true}
                        showBorder={true}>
                        {this.state.currentCategory === "aggiunta" ? this._menuAggiunta() : this._menuMateriali()}
                    </SectionLayout>
                </SectionLayout>
            </Fragment>
        )
    }
}
