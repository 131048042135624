import React, {RefObject} from "react";
import "./DefaultInput.scss";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import {toBase64} from "../../Utils/CommonFunctions";
import ResponsiveLabel from "../ResponsiveLabel/ResponsiveLabel";
import ImageService from "../../Servicies/ImageService";

export interface DefaultInputProps{
    value?: string,
    onChange?: (value: string) => void,
    onFileUpload?: (fileName: string, fileType: string, file?: File, base64Encoding?: string,) => void,
    placeHolder?: string,
    disabled?: boolean,
    readonly?: boolean
    inputRef?: RefObject<HTMLInputElement>,
    type?: "text" | "password" | "number" | "search" | "file" | "color",
    min?: number,
    max?: number,
    step?: number
}

export interface DefaultInputState{
    showPassword: boolean
}

export default class DefaultInput extends React.Component<DefaultInputProps, DefaultInputState>{
    constructor(props: Readonly<DefaultInputProps> | DefaultInputProps) {
        super(props);
        this.state = {
            showPassword: false
        }
    }

    private _uploadFiles(files: FileList){
        if(files.length > 0){
            const target = files.item(0);
            const splittedName = target.name.split('.');
            const type = splittedName.pop();
            toBase64(target).then(data => {
                this.props.onFileUpload && this.props.onFileUpload(data.name, type.toLowerCase().trim(), target, data.base64);
            }).catch(() => console.error("Errore nella conversione base64 del file"));
        }
    }

    private _uploadFile(){
        const input = document.createElement("input");
        input.type = "file";
        input.addEventListener("change", () => {
            this._uploadFiles(input.files);
        });
        input.click();
    }

    private _dragUpload(ev: React.DragEvent<HTMLSpanElement>){
        if(ev.dataTransfer.files.length > 0){
            this._uploadFiles(ev.dataTransfer.files);
        }
    }

    public render() {
        return (
            <IfContainer
                condition={!this.props.readonly}
                elseComponent={<ResponsiveLabel content={this.props.value} type={"medium"} alignment={"right"} italic={true}/>}>
                <div className={`InputWrapper`}>
                    <span
                        className={`${this.props.type === 'file' && 'UploadZone'}`}
                        onClick={() => {
                            this.props.type === 'file' && this._uploadFile();
                        }}
                        onDragEnter={ev => ev.preventDefault()}
                        onDragLeave={ev => ev.preventDefault()}
                        onDragOver={ev => ev.preventDefault()}
                        onDrop={ev => {
                            ev.preventDefault();
                            this.props.type === 'file' && this._dragUpload(ev);
                        }}>
                        <input
                            ref={this.props.inputRef}
                            type={this.props.type && this.props.type !== "file" ? (this.state.showPassword ? "text" : this.props.type) : "text"}
                            className={`DefaultInput ${this.props.type === "file" && "noEdit"} ${this.props.type === 'color' && 'colorMode'}`}
                            value={this.props.type !== 'file' ? this.props.value : this.props.value.length === 0 ? 'Trascina o carica un file' : this.props.value}
                            onChange={(event) => {this.props.onChange && this.props.type !== "file" && this.props.onChange(event.target.value)}}
                            placeholder={this.props.placeHolder}
                            disabled={this.props.disabled}
                            min={this.props.min}
                            max={this.props.max}
                            step={this.props.step}/>
                        {
                            this.props.type === 'password' &&
                            <img
                                alt={"HideIcon"}
                                className={"HideIcon"}
                                src={this.state.showPassword ? ImageService.getImage('eyeIcon') : ImageService.getImage('keyIcon')}
                                onClick={() => this.setState(oldState => ({showPassword: !oldState.showPassword}))}/>
                        }
                    </span>
                </div>
            </IfContainer>
        );
    }
}
