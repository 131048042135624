import React from "react";
import RedirectService from "../../Servicies/RedirectService";
import UserService from "../../Servicies/UserService";
import {Rest} from "tici_commons";
import LabelLoader from "../../Primary/LabelLoader/LabelLoader";
import LocalStorageService from "../../Servicies/LocalStorageService";
import TopLevelComponentStorage from "./TopLevelComponentStorage";
import Materiali from "../../DatabaseData/Materiali";
import Modello from "../../DatabaseData/Modello";

export interface PageProps{
    pageName: string,
    publicPage: boolean,
    pageComponent: any
}
export class Page extends React.Component<PageProps, {}>{
    public render() {
        return (
            this.props.pageComponent
        );
    }
}

export const RouterContext = React.createContext<{changePage: (pageName: string) => void}>(undefined);

export interface SessionAndAccessValidatorProps {
    level: number,
    notFountPage: React.ReactElement<PageProps>,
    children: React.ReactElement<PageProps> | React.ReactElement<PageProps>[]
}
export interface SessionAndAccessValidatorState {
    currentLevelPath: string;
    currentPageComponent: React.ReactElement<PageProps>;
    notFound: boolean;
    isLoading: boolean
}
export default class SessionAndAccessValidator extends React.Component<SessionAndAccessValidatorProps, SessionAndAccessValidatorState> {
    private _listenerId: number;                //Id del listener per il cambio pagina
    private _checkLoginDelay = 60000;           //Tempo tra un controllo e l'altro della validazione login
    private _checkLoginId: number;              //Id del ciclo di controllo

    constructor(props: Readonly<SessionAndAccessValidatorProps> | SessionAndAccessValidatorProps) {
        super(props);
        this.state = {
            currentLevelPath: undefined,
            currentPageComponent: undefined,
            notFound: false,
            isLoading: true
        }
    }

    /**
     * Estrae il path dell'url per il livello di riferimento
     * @param url Url da cui recuperare il livello
     * @private
     */
    private _extractMyLevel(url: string): string{
        let esito = "";

        const reducedUrl = url.replaceAll("http://", "").replaceAll("https://", "").split('?')[0];
        const splittedUrl = `${reducedUrl}/`.split("/");
        if(this.props.level + 1  < splittedUrl.length)
            esito = splittedUrl[this.props.level + 1];

        return esito;
    }

    /**
     * Recupera e cambia il componente renderizzato
     * @private
     */
    private async _changeCurrentPage(nextLevelPath: string){
        if(this.state.currentLevelPath !== nextLevelPath){
            this.setState({notFound: false});
            const children = (React.Children.toArray(this.props.children) as React.ReactElement<PageProps>[]).find(children => children.props.pageName === nextLevelPath);
            let notFound = true;

            if(children){
                let canUpdate = false;
                if(children.props.publicPage){
                    canUpdate = true;
                }else{
                    canUpdate = true; //TODO: Eliminare e rimpiazzare questa parte
                    /*
                    const esitoControllo = await RedirectService.ValidaPaginaUtente(newPage);
                    if(esitoControllo)
                        canUpdate = true;*/
                }

                if(canUpdate) {
                    notFound = false;
                    this.setState({
                        currentPageComponent: children,
                        currentLevelPath: nextLevelPath
                    });
                }
            }

            this.setState({notFound});
        }
    }

    /**
     * Controlla lo stato dell'href e nel caso cambia la pagina
     * @private
     */
    private async _checkCurrentHref(){
        const myCurrentLevel = this._extractMyLevel(window.location.href);
        if(this.state.currentLevelPath !== myCurrentLevel){
            console.warn("Aggiornamento componente a livello:", this.props.level);
            this.setState({isLoading: true});
            await this._changeCurrentPage(this._extractMyLevel(window.location.href));
            this.setState({isLoading: false});
            this._checkStatoPrivacy();
        }
    }

    /**
     * Controlla lo stato della privacy dell' utente per determinare se é stata accettata oppure no
     * @private
     */
    private async _checkStatoPrivacy(){
        if(await UserService.IsLogged()){
            const userContext = await UserService.GetUserContext();
            if(userContext){
                if(!userContext.privacyAccettata && !window.location.href.includes('homepage/account')){
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                        "Privacy non accettata",
                        "Sembra che per quest' account non siano state accettate le politiche di privacy. Per poter risolvere il problema e continuare ad utilizzare il sito é necessario accettarle dal pannello utente.",
                        "SingleButton",
                        () => {
                            RedirectService.GoToAccount();
                        }
                    )
                }
            }
        }
    }

    /**
     * Effettua il controllo dei queryParams
     * @private
     */
    private _controllaQueryParams(): boolean{
        let esito = false;

        const params = new URLSearchParams(window.location.search);
        const idOrdine = params.get('idOrdine');
        if(idOrdine && !isNaN(parseInt(idOrdine))){
            esito = true
            LocalStorageService.RedirectOrdineNotifica = parseInt(idOrdine);
            RedirectService.GoToOrdini();
        }

        return esito;
    }

    /**
     * Effettua il controllo dei queryParams per le pagine pubbliche
     * @private
     */
    private _controllaQueryParamsPulic(): boolean{
        let esito = false;

        const params = new URLSearchParams(window.location.search);
        const chiaveEmail = params.get('chiaveEmail');
        if(chiaveEmail){
            esito = true;
            RedirectService.GoTo("AttivazioneEmail", `/attivazione_email?key=${chiaveEmail}`);
        }

        return esito;
    }

    /**
     * Effettua la restore dell'uid dell'utenza loggata
     * @private
     */
    private async _restoreUid(){
        if(!this._controllaQueryParamsPulic()){
            this.setState({isLoading: true});

            const uid = UserService.UID;
            if(uid)
                Rest.UID = uid;

            const esito = await this._controllaSessioneAttiva();
            if(esito){
                await this._integraDettagliAccount();
                if(!this._controllaQueryParams())
                    RedirectService.GoToHomepage();
            }

            this.setState({isLoading: false});
        }
    }

    /**
     * Controlla che la sessione utente sia attiva altrimenti esegue il logout
     * @private
     */
    private async _controllaSessioneAttiva(): Promise<boolean>{
        const isLogged = await UserService.IsLogged();
        if(UserService.UID && !isLogged){
            UserService.Logout();
            RedirectService.GoToLogin();
        }
        return isLogged;
    }

    /**
     * Integra le informazioni speciali ad uso esclusivo del fotografo: Materiali e modelli
     * @private
     */
    private async _integraDettagliAccount(){
        await Materiali.IntegraMateriali();
        await Modello.IntegraModelli();
    }

    public async componentDidMount() {
        if(this.props.level === 0){
            await this._restoreUid();
        }
        await this._checkCurrentHref();
        this._listenerId = RedirectService.AddListener(() => {
            this._checkCurrentHref();
        });
        if(this.props.level === 0){
            this._checkLoginId = window.setInterval(() => {
                this._controllaSessioneAttiva();
            }, this._checkLoginDelay);
        }
    }

    public componentWillUnmount() {
        RedirectService.RemoveListener(this._listenerId);
        window.clearInterval(this._checkLoginId);
    }

    public render() {
        return (
            this.state.isLoading ?
                <LabelLoader label={"Stiamo caricando la pagina"}/> :
                this.state.notFound ?
                    this.props.notFountPage :
                    this.state.currentPageComponent
        );
    }
}
