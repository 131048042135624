import React from "react";
import Modello from "../../DatabaseData/Modello";
import {CaricaModello, RecuperaDatiModello} from "../GestioneModelli/GestioneModelliCommonFunction";
import {ModelloConfigurabile} from "../GestioneModelli/ModelloConfigurabile";
import RenderizzatoreModelli from "../GestioneModelli/RenderizzatoreModelli/RenderizzatoreModelli";
import {Group} from "three";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import LabelLoader from "../LabelLoader/LabelLoader";
import Formati from "../../DatabaseData/Formati";
import { NomeSezioneInformazioneType } from "tici_commons";
import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import Config from "../../Static/Config";

export interface MiniaturaInteragibileProps{
    nomeModello: string,
    idOrdine: number,
    nomeManager: 'copertina' | 'copertinaridotto' | 'custodia' | 'custodiaridotto'
    aspectRatioModello: string,
    datiMiniatura: NomeSezioneInformazioneType[]
    modalitaAlternativa?: boolean,
}

export interface MiniaturaInteragibileState{
    isLoading: boolean
}

export default class MiniaturaInteragibile extends React.Component<MiniaturaInteragibileProps, MiniaturaInteragibileState>{
    private _gruppoRendering: Group = new Group();

    public constructor(props: MiniaturaInteragibileProps, context: any) {
        super(props, context);
        this.state = {
            isLoading: false
        }
    }

    public async componentDidMount() {
        await this._initMiniatura();
    }

    private async _initMiniatura(){
        try{
            if(this.props.nomeModello){
                const modello = Modello.RecuperaModelloDaNome(this.props.nomeModello);
                const modelloCaricato = await CaricaModello(modello.nomeConfigurazione);

                if(modelloCaricato){
                    const datiModello = await RecuperaDatiModello(modelloCaricato.modello3D);
                    if(datiModello){
                        const modelloConfigurabile = new ModelloConfigurabile(datiModello, modelloCaricato.configurazione);
                        const sezioniConfigurabili = await modelloConfigurabile.setupModelloConfigurabile();

                        modelloConfigurabile.aspectRatioModello = Formati.GetAspectRatioFromFormatoString(this.props.aspectRatioModello) || "1 / 1";
                        modelloConfigurabile.modalitaVisualizzazioneAlternativa = this.props.modalitaAlternativa;

                        //TODO: Implementare la gestione dello storage
                        sezioniConfigurabili.forEach(sezioneConfigurabile => {
                            const storageData: StorageData[] = [];

                            for(const datoMiniatura of this.props.datiMiniatura){
                                if(datoMiniatura[1].informazioneImmagine){
                                    storageData.push(StorageData.StorageDataWithUrl(
                                        `${datoMiniatura[0]}-immagine`,
                                        datoMiniatura[1].informazioneImmagine.nomeImmagine,
                                        `${Config.PublicPath}/OrdineModelloFile/${this.props.idOrdine}/${this.props.nomeManager}/${datoMiniatura[1].informazioneImmagine.nomeImmagine}`
                                    ));
                                }
                                if(datoMiniatura[1].informazioneUv){
                                    storageData.push(StorageData.StorageDataWithUrl(
                                        `${datoMiniatura[0]}-uv`,
                                        datoMiniatura[1].informazioneUv.nomeUv,
                                        `${Config.PublicPath}/OrdineModelloFile/${this.props.idOrdine}/${this.props.nomeManager}/${datoMiniatura[1].informazioneUv.nomeUv}`
                                    ))
                                }
                            }

                            sezioneConfigurabile.aggiornaVisualizzazioneModello(this.props.datiMiniatura, storageData);
                        })

                        this._gruppoRendering.add(modelloConfigurabile);
                        this.setState({isLoading: false});
                    }
                }

            }
        }catch(e){
            console.error("Errore durante l'inizializzazione del modello 3D da renderizzare", e);
        }
    }

    public render() {
        return (
            <IfContainer
                condition={!this.state.isLoading}
                elseComponent={
                    <LabelLoader
                        label={"Caricamento miniatura"}/>
                }>
                <RenderizzatoreModelli
                    gruppoRendering={this._gruppoRendering}
                    larghezzaCanvas={"100%"}
                    aspectRatioCanvas={"3 / 2"}/>
            </IfContainer>
        );
    }
}
