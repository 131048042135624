import React, {Fragment} from "react";
import CategorySwitcherElement from "../../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";

import CategorySwitcher from "../../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import AutoRefreshComponent from "../../../../Core/Arch/AutoRefreshComponent";
import VistaModelli from "./VisteGestore/VistaModelli";
import VistaConfigurazioni from "./VisteGestore/VistaConfigurazioni";
import {
    ConfigurazioneGestore3DBridgeContext,
    ConfigurazioneGestore3DBridgeState
} from "../Bridge/ConfigurazioneGestore3DBridge";
import DataService from "../../../../Servicies/DataService";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import ImageService from "../../../../Servicies/ImageService";

export type MenuCategory = "modelli" | "configurazioni";

export interface GestoreModelli3DState{
    currentCategory: MenuCategory;

    listaModelli: string[],
    listaConfigurazioni: string[],

    caricamentoDaBridge: boolean
}

export default class GestoreModelli3D extends AutoRefreshComponent<{}, GestoreModelli3DState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            currentCategory: "modelli",
            listaModelli: [],
            listaConfigurazioni: [],
            caricamentoDaBridge: false
        }
    }

    public cycleData = async () => {
        this.setState({listaModelli: await DataService.ListaModelli(), listaConfigurazioni: await DataService.ListaConfigurazioni()});
    }

    componentDidMount() {
        super.componentDidMount();
        const bridge = this.context as ConfigurazioneGestore3DBridgeState;
        if(bridge && bridge.hasData() && bridge.payload){
            this.setState({
                currentCategory: "configurazioni",
                caricamentoDaBridge: true,
                listaConfigurazioni: [bridge.metaData.configuratore.configurazioneSelezionata]
            })
        }
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "modelli"}
                        elementIndex={0}
                        label={"Modelli"}
                        icon={ImageService.getImage('_3dIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "modelli"})}/>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "configurazioni"}
                        elementIndex={1}
                        label={"Configurazioni"}
                        icon={ImageService.getImage('wrenchIcon')}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "configurazioni"})}/>
                </CategorySwitcher>
                <SectionLayout size={"largeRelative"}>
                    <ResponsiveLabel alignment={"center"} content={"Gestisci i modelli3D e le loro configurazioni"} type={"large"} uppercase={true} bold={true}/>
                    <SectionLayout size={"mediumRelative"}  addPadding={true} showBorder={true} lightBackground={true}>
                        {
                            this.state.currentCategory === "modelli" ?
                                <VistaModelli
                                    listaModelli={this.state.listaModelli}
                                    listaConfigurazioni={this.state.listaConfigurazioni} /> :
                                <VistaConfigurazioni
                                    listaConfigurazioni={this.state.listaConfigurazioni}
                                    caricamentoDaBridge={this.state.caricamentoDaBridge}
                                    azzeraCaricamentoDaBridge={() => this.setState({caricamentoDaBridge: false})}/>
                        }
                    </SectionLayout>
                </SectionLayout>
            </Fragment>
        );
    }
}

GestoreModelli3D.contextType = ConfigurazioneGestore3DBridgeContext;
