import {
    DettaglioDataModel,
    DettaglioOrdineModel,
    MiniaturaPostBodyInterface,
    MiniaturaTipoMiniaturaType,
    OrdineDataModel,
    OrdineDataPostBodyInterface,
    OrdineFileNWSPostBodyInterface,
    OrdineFilePostBodyInterface,
    OrdineFilePutBodyInterface, OrdineModelloFilePostBodyInterface,
    OrdinePostBodyInterface,
    OrdinePutBodyInterface,
    Rest,
    TipoDatoOrdine,
    TipoFiltroType
} from "tici_commons";
import Config from "../Static/Config";
import {fromMsToTime} from "../Utils/CommonFunctions";

export default class OrdineService{
    public static async InviaOrdine (nomeOrdine: string): Promise<number | false> {
        let esito: number | false = false;

        const body: OrdinePostBodyInterface = {
            nomeOrdine
        }

        try{
            const ordineResponse = await Rest.Post(
                `${Config.PrivatePath}/Ordine`,
                undefined,
                body
            );
            if(ordineResponse && ordineResponse.statusCode === 200)
                esito = ordineResponse.response as unknown as number;
        }catch (e){
            console.error("Errore nell'invio dell'ordine", String(e));
        }

        return esito
    }
    public static async UploadOrdineData (idOrdine: number, tipoDato: TipoDatoOrdine, dati: {[key: string]: any}, metaDati?: {[key: string]: any}): Promise<boolean> {
        let esito = false;

        const body: OrdineDataPostBodyInterface = {
            idOrdine,
            tipoDato,
            dati,
            metaDati
        }

        try {
            const dataResponse = await Rest.Post(
                `${Config.PrivatePath}/OrdineData`,
                undefined,
                body
            )

            if(dataResponse && dataResponse.statusCode === 200)
                esito = true;
        }catch(e){
            console.error("Errore nell'invio dei dati dell'ordine", String(e));
        }

        return esito;
    }
    public static async OrdineFilePath(idOrdine: number): Promise<string>{
        let esito = "";

        try {
            const response = await Rest.Get<string>(`${Config.PrivatePath}/OrdineFile/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Errore nel recupero della locazione dell'ordine");
        }

        return esito;
    }
    public static async ComprimiDati (idOrdine: number): Promise<boolean>{
        let esito = false;

        const body: OrdineFilePutBodyInterface = {
            idOrdine
        }
        try {
            const esitoAbilitazione = await Rest.Put<string>(`${Config.PrivatePath}/OrdineFile`, undefined, body);
            if(esitoAbilitazione && esitoAbilitazione.statusCode === 200)
                esito = true
        }catch (e){
            console.error("Errore nella compressione dei dati dell'ordine", String(e));
        }

        return esito;
    }
    public static async AbilitaUploadOrdineFile (idOrdine: number, nomeOrdine: string): Promise<string | false> {
        let esito: string | false = false;

        const body: OrdineFilePostBodyInterface = {
            idOrdine,
            nomeOrdine
        }
        try {
            const esitoAbilitazione = await Rest.Post<string>(`${Config.PrivatePath}/OrdineFile`, undefined, body);
            if(esitoAbilitazione)
                esito = esitoAbilitazione.response;
        }catch (e){
            console.error("Errore nell'abilitazione dell'upload", String(e));
        }

        return esito;
    }
    public static async UploadOrdineMiniatura (idOrdine: number, tipoMiniatura: MiniaturaTipoMiniaturaType, base64Miniatura: string, includiNellPacchettoOrdine = false): Promise<boolean> {
        let esito = false;

        const body: MiniaturaPostBodyInterface = {
            idOrdine,
            tipoMiniatura,
            base64Miniatura
        }

        try{
            const response = await Rest.Post(
                `${Config.PrivatePath}/MiniaturaOrdine`,
                undefined,
                body
            );
            esito = response && response.statusCode === 200;
            if(esito && includiNellPacchettoOrdine){
                esito = await OrdineService.UploadOrdineFile(idOrdine, `${tipoMiniatura}.png`, base64Miniatura, '/Miniature',)
            }
        }catch (e){
            console.error("Errore nell'invio di una miniatura", String(e));
        }

        return esito;
    }
    public static GetOrdineMiniaturaUrl (idOrdine: number, tipoMiniatura: MiniaturaTipoMiniaturaType): string{
        return `${Config.PublicPath}/Miniatura/${tipoMiniatura}/${idOrdine}`;
    }
    public static async ValidaOrdine (idOrdine: number): Promise<boolean> {
        let esito = false;

        const body: OrdinePutBodyInterface = {
            idOrdine
        }

        try{
            const attivazioneResponse = await Rest.Put(`${Config.PrivatePath}/Ordine`, undefined, body);
            esito = attivazioneResponse && attivazioneResponse.statusCode === 200;
        }catch (e){
            console.error("Errore nella conferma dell'ordine", String(e));
        }

        return esito;
    }
    public static async RecuperaDateTimeOrdini(): Promise<DettaglioDataModel[]>{
        const esito: {dataTicket: string, statoConferma: number}[] = [];

        try{
            const result = await Rest.Get<DettaglioDataModel[]>(`${Config.PrivatePath}/OrdineDateTime`);
            if(result && result.statusCode === 200)
                esito.push(...result.response);
        }catch(e){
            console.error("Errore nella chiamata ai date time dell'ordine", String(e));
        }

        return esito;
    }
    public static async DettaglioOrdini(tipologiaFiltro: TipoFiltroType, filtro: string): Promise<DettaglioOrdineModel[]>{
        const esito: DettaglioOrdineModel[] = [];

        const endPoint = `${Config.PrivatePath}/Ordine/${tipologiaFiltro}_${filtro}`;
        try{
            const result = await Rest.Get<DettaglioOrdineModel[]>(endPoint);
            if(result && result.statusCode === 200)
                esito.push(...result.response);
        }catch(e){
            console.error("Errore nel recupero del dettaglio ordini", String(e));
        }

        return esito;
    }
    public static async EliminaOrdine(idOrdine: number): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Delete(`${Config.PrivatePath}/Ordine/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore nell'eliminazione dell'ordine", String(e));
        }

        return esito;
    }
    public static async RecuperaOrdineData(idOrdine: number): Promise<OrdineDataModel[]>{
        let esito: OrdineDataModel[] = [];

        try{
            const response = await Rest.Get<OrdineDataModel[]>(`${Config.PrivatePath}/OrdineData/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Non é stato possibile recuperare le informazioni dell'ordine", String(e));
        }

        return esito;
    }
    public static async UploadOrdineFile(idOrdine: number, nomeFile: string, base64Data: string, sottoPath?: string, progressStatus?: (status: number) => void): Promise<boolean>{
        let esito = false;

        try {
            const body: OrdineFileNWSPostBodyInterface = {
                idOrdine,
                nomeFile,
                base64Data,
                sottoPath
            }
            const response = await Rest.PostXML(
                `${Config.PrivatePath}/OrdineFileNWS`,
                undefined,
                body,
                (current, total) => progressStatus && progressStatus(current / total)
            );
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile inviare un file dell'ordine al server", String(e));
        }

        return esito;
    }
    public static async OrdineFileExist(idOrdine: number): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Get<boolean>(`${Config.PrivatePath}/OrdineFileExist/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non é stato possibile recuperare l'esistenza del file")
        }

        return esito;
    }
    public static async UploadOrdineModelloFile(
        idOrdine: number,
        nomeFile: string,
        nomeManagerRendering: string,
        base64Data: string
    ): Promise<boolean>{
        let esito = false;

        if(idOrdine !== undefined && nomeFile && nomeManagerRendering && base64Data){
            try{
                const body: OrdineModelloFilePostBodyInterface = {
                    idOrdine,
                    nomeFile,
                    nomeManagerRendering,
                    base64Data
                }
                const response = await Rest.Post(`${Config.PrivatePath}/OrdineModelloFile`, undefined, body);
                esito = response && response.statusCode === 200;
            }catch (e){
                console.error("Non é stato possibile caricare il file del modello 3d per l'odine", idOrdine, e);
            }
        }

        return esito;
    }
}
