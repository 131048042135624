import React, {Fragment} from "react";

import {TextLine} from "../../Widgets/Form/FComponents";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Button from "../../Core/Buttons/Button";
import {LRRLayout} from "../../PagesLayout/PagesLayout";
import RedirectService from "../../Servicies/RedirectService";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import Responsive2Col from "../../Layout/Responsive2Col/Responsive2Col";
import ContainerLayout from "../../Layout/ContainerLayout/ContainerLayout";
import UserService from "../../Servicies/UserService";
import {LoginResponseAccountStatusEnum} from "tici_commons";
import Materiali from "../../DatabaseData/Materiali";
import Modello from "../../DatabaseData/Modello";

export interface LoginPageState{
    email: string,
    password: string
}
export default class LoginPage extends React.Component<{}, LoginPageState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            email: "",
            password: ""
        }
    }

    /**
     * Mostra un messaggio di login
     * @param titolo Titolo del messaggio di login
     * @param contenuto Contenuto del messaggio di login
     * @private
     */
    private _mostraMessaggioLogin(titolo = "Errore Generico", contenuto = "Non è stato possibile effettuare il login"){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(titolo, contenuto, "SingleButton", () => {
            TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
        });
    }

    /**
     * Effettua il login sul sito
     * @private
     */
    private async _login() {
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Login", "Tentativo di login");

        const login = await UserService.Login(this.state.email, this.state.password);
        if(login !== false){
            switch (login){
                case LoginResponseAccountStatusEnum.EmailNonConfermata:
                    TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                    this._mostraMessaggioLogin(
                        "Email non attiva",
                        "La mail non risulta ancora essere attiva"
                    );
                    break;
                case LoginResponseAccountStatusEnum.AccountNonConfermato:
                    TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                    this._mostraMessaggioLogin(
                        "Account non attivo",
                        "L'account non è stato attivato dagli amministratori"
                    );
                    break;
                case LoginResponseAccountStatusEnum.AccountConfermato:
                    const userContext = await UserService.GetUserContext();
                    await Materiali.IntegraMateriali();
                    await Modello.IntegraModelli();
                    TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                    if(!userContext){
                        UserService.Logout();
                        this._mostraMessaggioLogin();
                    }else RedirectService.GoToHomepage();

                    break;
            }
        }else {
            TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
            this._mostraMessaggioLogin();
        }
    }

    public render() {
        return (
            <Fragment>
                <LRRLayout versioneEstesa={true}>
                    <ContainerLayout size={"smallRelative"} center={true}>
                        <form onSubmit={ev => {ev.preventDefault(); this._login()}} style={{width: "100%"}}>
                            <SectionLayout size={"largeRelative"}>
                                <TextLine
                                    label={"Email"}
                                    type={"text"}
                                    value={this.state.email}
                                    onChange={v => this.setState({email: v})}/>
                                <TextLine
                                    label={"Password"}
                                    type={"password"}
                                    value={this.state.password}
                                    onChange={v => this.setState({password: v})}/>
                                <Button
                                    content={"Login"}
                                    type={"medium"}
                                    disabled={ this.state.email.length === 0 || this.state.password.length === 0}
                                    buttonType={"full-normal-login"}
                                    submit={true}
                                    alignment={"center"}/>
                            </SectionLayout>
                        </form>
                        <Responsive2Col>
                            <Button
                                content={"Recupera password"}
                                type={"medium"}
                                alignment={"right"}
                                buttonType={"full-normal-login"}
                                onClick={() => {RedirectService.GoToRecupero()}}/>
                            <Button
                                content={"Registrati"}
                                type={"medium"}
                                buttonType={"full-normal-login"}
                                alignment={"center"}
                                onClick={() => {RedirectService.GoToRegistrazione()}}/>
                        </Responsive2Col>
                    </ContainerLayout>
                </LRRLayout>
            </Fragment>
        );
    }
}
