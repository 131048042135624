export default class Config{
    public static TabletBkp = 800;
    public static DesktopBkp = 1200;

    public static BackEnd: string = `${window.location.href.startsWith('https') ? 'https' : 'http'}://totem.ticialbum.com:${window.location.href.startsWith('https') ? '4000' : '4001'}`;
    public static PrivatePath: string = `${Config.BackEnd}/priv`;
    public static PublicPath: string = `${Config.BackEnd}/public`;
    public static UserImage: string = `${Config.BackEnd}/public/UserImage`;
    public static NoMapping: string = `${Config.BackEnd}/public/NoMapping`;
}
