import React from "react";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Button from "../../Core/Buttons/Button";
import RedirectService from "../../Servicies/RedirectService";

export default class SuperUser extends React.Component<{}, {}>{
    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel alignment={"center"} content={"Accedi alla funzionalità da Super User"} type={"large"} uppercase={true} bold={true}/>
                <SectionLayout size={"mediumRelative"} showBorder={true} addPadding={true} lightBackground={true}>
                    <Button
                        content={"Gestione materiali"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Gestione materiali", "/homepage/gestione_materiali")}/>
                    <Button
                        content={"Gestione modelli 3d"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Gestione modelli 3d", "/homepage/gestione_modelli_3d")}/>
                    <Button
                        content={"Configuratore modelli 3d"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Configuratore modelli 3d", "/homepage/configuratore_modelli_3d")}/>
                    <Button
                        content={"Gestione vincoli modelli e materiali"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Vincoli", "/homepage/vincoli")}/>
                    <Button
                        content={"Rotte"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Rotte", "/homepage/analytics_rotte")}/>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
