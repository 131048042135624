import "./ChatIcon.scss";
import messageIcon from "../../Media/Images/Icons/messageIcon.png";

import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import AutoRefreshComponent from "../../Core/Arch/AutoRefreshComponent";
import ChatService from "../../Servicies/ChatService";
import {NotificheChatResponseModel, UserContextResponseModel} from "tici_commons";
import UserService from "../../Servicies/UserService";

export interface ChatIconProps{
    ordineId: number
    nascondiDoppioBadge?: boolean
    standAloneMessaggiInterni?: boolean
}

export interface ChatIconState{
    messageCount: NotificheChatResponseModel,
    userContext: UserContextResponseModel
}

export default class ChatIcon extends AutoRefreshComponent<ChatIconProps, ChatIconState>{
    constructor(props: Readonly<ChatIconProps> | ChatIconProps) {
        super(props);
        this.Delay = 3 * 60 * 1000; //Aggiorna ogni 3 minuti
        this.state = {
            messageCount: {nonLettiChatGlobale: 0, nonLettiChatInterna: 0},
            userContext: undefined
        };
    }

    public componentDidMount() {
        this._initUserContext();
    }

    private _initUserContext(){
        if(!this.state?.userContext) {
            UserService.GetUserContext().then(userContext => {
                this.setState({userContext});
            });
        }
    }

    public cycleNotificheChat = async () => {
        this.setState({messageCount: await ChatService.MessaggiNonLettiOrdine(this.props.ordineId)});
    }

    public render() {
        return (
            <div className={"ChatContainerIcon"}>
                <IfContainer condition={this.visualizzaBadge}>
                    <span className={`counter ${this.props.standAloneMessaggiInterni && 'standAloneColor'}`}>
                        {this.props.standAloneMessaggiInterni ? this.state.messageCount.nonLettiChatInterna : this.state.messageCount.nonLettiChatGlobale}
                        <IfContainer
                            condition={
                                this.state.messageCount.nonLettiChatInterna > 0 &&
                                this.state?.userContext?.tipoUtente === 'amministrazione' &&
                                !this.props.standAloneMessaggiInterni &&
                                !this.props.nascondiDoppioBadge
                            }>
                            <span className={"sottoCounter"}>
                                +{this.state.messageCount.nonLettiChatInterna}
                            </span>
                        </IfContainer>
                    </span>
                </IfContainer>
                <img alt={"ChatIcon"} className={`ChatIcon`} src={messageIcon}/>
            </div>
        );
    }

    public get visualizzaBadge(): boolean{
        let esito = false;
        this._initUserContext();
        if(this.state?.userContext?.tipoUtente === 'amministrazione'){
            esito = this.props.standAloneMessaggiInterni ?
                this.state.messageCount.nonLettiChatInterna > 0 :
                this.state.messageCount.nonLettiChatGlobale > 0 || this.state.messageCount.nonLettiChatInterna > 0;
        }else{
            esito = this.state.messageCount.nonLettiChatGlobale > 0;
        }
        return esito;
    }
}
