//QG 30/06/2024 MD

import {ConfigurazioneBlank, ConfigurazioneRenderizzatoreInterface, ConfigurazioneSezioneBlank} from "tici_commons";
import Modello from "../../DatabaseData/Modello";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Mesh, Object3D} from "three";

export interface CaricaModelloResponseInterface {
    modello3D: string,
    configurazione: ConfigurazioneRenderizzatoreInterface
}

/**
 * Carica modello e configurazione per un Modello3D da renderizzare
 * @param nomeConfigurazione Nome della configurazione da scaricare
 * @constructor
 */
export const CaricaModello = async (nomeConfigurazione: string): Promise<CaricaModelloResponseInterface | false> => {
    let esito: CaricaModelloResponseInterface | false = false;

    try {
        const configurazione = await Modello.GetConfigurazione(nomeConfigurazione);
        if(configurazione){
            const modello3D = await Modello.GetModello(configurazione.pathModello);
            if(modello3D)
                esito = {modello3D, configurazione: ConfigurazioneBlank(configurazione.configurazioneModello)};
        }

    }catch (e){
        console.error("Errore nel caricamento del modello", String(e));
    }

    return esito;
}

export const RecuperaDatiModello = async (modello3D: string): Promise<Object3D | false> => {
    let esito: Object3D | false = false;

    try{
        const loader = new OBJLoader();
        const url = modello3D.includes('base64') ? modello3D : URL.createObjectURL(new Blob([modello3D], {type: 'text/plain'}));
        esito = await loader.loadAsync(url);
        URL.revokeObjectURL(url);
    }catch (e){
        console.error("Non é stato possibile recuperare i dati del modello", String(e));
    }

    return esito;
}

/**
 * Carica modello e configurazione per un Modello3D da renderizzare in maniera manuale
 * @param fileModello File del modello da caricare
 * @constructor
 */
export const CaricaModelloManualmente = async (fileModello: File): Promise<CaricaModelloResponseInterface | false> => {
    let esito: CaricaModelloResponseInterface | false = false;

    try {
        const modello3D = await fileModello.text();
        const configurazione = ConfigurazioneBlank();

        const loader = new OBJLoader();
        const url = URL.createObjectURL(new Blob([modello3D], {type: 'text/plain'}));
        const object = await loader.loadAsync(url);

        object.traverse(object => {
            if(object instanceof Mesh)
                configurazione.configurazioniSezioni.push(ConfigurazioneSezioneBlank(object.name));
        })
        URL.revokeObjectURL(url);

        esito = {modello3D, configurazione}
    }catch (e){
        console.error("Errore nel caricamento del modello", String(e));
    }

    return esito;
}
