import React from "react";
import messageIcon from "../../../../Media/Images/Icons/chatIcon.png";
import sendIcon from "../../../../Media/Images/Icons/sendIcon.png";
import trashIcon from "../../../../Media/Images/Icons/trashIcon.png"
import "./ChatOverlaySender.scss";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import Loader from "../../../../Core/Loader/Loader";
import ChatService from "../../../../Servicies/ChatService";
import TopLevelComponentStorage from "../../../../Core/Arch/TopLevelComponentStorage";

export interface ChatOverlaySenderProps {
    ordineId?: number,
    messaggioInterno?: boolean,
    onSendMessage?: () => void
}

export interface ChatOverlaySenderState {
    currentMessage: string,
    nomeAllegato: string,
    loading: boolean,
}

export default class ChatOverlaySender extends React.Component<ChatOverlaySenderProps, ChatOverlaySenderState> {
    private _allegatoBase64: string;

    constructor(props: Readonly<ChatOverlaySenderProps> | ChatOverlaySenderProps) {
        super(props);
        this.state = {
            currentMessage: "",
            nomeAllegato: "",
            loading: false
        }
    }

    /**
     * Invia un messaggio sul server
     * @private
     */
    private async _sendMessage(){
        this.setState({loading: true});
        if(await ChatService.InviaMessaggio(this.state.currentMessage, this.props.ordineId, this.props.messaggioInterno, this.state.nomeAllegato, this._allegatoBase64)){
            this.props.onSendMessage && this.props.onSendMessage();
            this.setState({
                currentMessage: "",
                nomeAllegato: ""
            });
            this._allegatoBase64 = undefined;
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Errore",
                "Non è stato possibile recapitare il messaggio"
            );
        }
        this.setState({loading: false});
    }

    /**
     * Carica le informazioni per inviare l'allegato sul server
     * @private
     */
    private _caricaAllegato(){
        if(!this.state.nomeAllegato){
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = false;

            input.addEventListener("change", async () => {
                if(input.files && input.files.length > 0){
                    const file = input.files.item(0);
                    if(/png|jpg|jpeg|zip|rar/.test(file.type.toLowerCase())){
                        const fileReader = new FileReader();

                        fileReader.addEventListener("loadend", () => {
                            this._allegatoBase64= fileReader.result as string;
                            this.setState({nomeAllegato: file.name});
                        });
                        fileReader.readAsDataURL(file);
                    }else{
                        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                            "Errore",
                            "Il formato dell'allegato non è corretto. Sono supportati i seguenti file: jpg, jpeg, png, zip, rar",
                            "SingleButton",
                            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
                        );
                    }
                }
            });

            input.click();
        }else {
            this.setState({nomeAllegato: ""});
            this._allegatoBase64 = undefined;
        }
    }

    public render() {
        return (
            <IfContainer
                condition={!this.state.loading}
                elseComponent={(
                    <span className={"ChatOverlaySender"}>
                        <ResponsiveLabel content={"Stiamo inviando il messaggio"} type={"medium"} alignment={"center"}/>
                        <Loader/>
                    </span>
                )}>
                <IfContainer condition={!!this.state.nomeAllegato}>
                    <ResponsiveLabel
                        content={`Allegato: ${this.state.nomeAllegato}`}
                        type={"small"}
                        alignment={"left"}
                        labelType={"recapLabel"}/>
                </IfContainer>
                <span className={"ChatOverlaySender"}>
                     <input
                         type={"text"}
                         className={"textField"}
                         value={this.state.currentMessage}
                         onChange={v => this.setState({currentMessage: v.target.value})}
                         onKeyUp={v => v.code === 'Enter' && this._sendMessage()}/>
                    <span className={"buttonZone"}>
                        <img
                            alt={"sendIcon"}
                            src={this.state.nomeAllegato ? trashIcon : sendIcon}
                            className={'button'}
                            onClick={() => this._caricaAllegato()}/>
                        <img
                            alt={"messageIcon"}
                            src={messageIcon}
                            className={'button'}
                            onClick={() => this._sendMessage()}/>
                    </span>
                </span>
            </IfContainer>
        );
    }
}
