import {Rest, VincoliMaterialiResponseModel, VincoliMaterialiRoutePostBodyInterface, VincoliModelliResponseModel, VincoliModelliRoutePostBodyInterface} from "tici_commons";
import Config from "../Static/Config";

export default class VincoliService{
    public static async GetVincoliMateriali(): Promise<VincoliMaterialiResponseModel[]>{
        const esito:VincoliMaterialiResponseModel[] = [];

        try{
            const response = await Rest.Get<VincoliMaterialiResponseModel[]>(`${Config.PrivatePath}/VincoliMateriali`);
            if(response && response.statusCode === 200){
                esito.push(...response.response)
            }
        }catch (e){
            console.error("Errore durante il recupero dei vincoli dei materiali", e);
        }

        return esito;
    }

    public static async InserisciVincoloMateriale(identificativoFotografo: number, nomeMateriale: string, famigliaMateriale: string, lussuriaMateriale: string): Promise<boolean>{
        let esito = false;

        try {
            const body: VincoliMaterialiRoutePostBodyInterface = {
                identificativoFotografo,
                nomeMateriale,
                famigliaMateriale,
                lussuriaMateriale
            };
            const response = await Rest.Post<boolean>(`${Config.PrivatePath}/VincoliMateriali`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore durante l'inserimento del vincolo sul materiale", e);
        }

        return esito;
    }

    public static async RimuoviVincoloMateriale(identificativoFotografo: number, nomeMateriale: string, famigliaMateriale: string, lussuriaMateriale: string): Promise<boolean>{
        let esito = false;

        try {
            const params = `${identificativoFotografo}/${nomeMateriale}/${famigliaMateriale}/${lussuriaMateriale}`;
            const response = await Rest.Delete<boolean>(`${Config.PrivatePath}/VincoliMateriali/${params}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore durante l'inserimento del vincolo sul materiale", e);
        }

        return esito;
    }

    public static async GetVincoliModelli(): Promise<VincoliModelliResponseModel[]>{
        const esito:VincoliModelliResponseModel[] = [];

        try{
            const response = await Rest.Get<VincoliModelliResponseModel[]>(`${Config.PrivatePath}/VincoliModelli`);
            if(response && response.statusCode === 200){
                esito.push(...response.response)
            }
        }catch (e){
            console.error("Errore durante il recupero dei vincoli dei modelli", e);
        }

        return esito;
    }

    public static async InserisciVincoloModello(identificativoFotografo: number, nomeModello: string): Promise<boolean>{
        let esito = false;

        try {
            const body: VincoliModelliRoutePostBodyInterface = {
                identificativoFotografo,
                nomeModello
            };
            const response = await Rest.Post<boolean>(`${Config.PrivatePath}/VincoliModelli`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore durante l'inserimento del vincolo sul modello", e);
        }

        return esito;
    }

    public static async RimuoviVincoloModello(identificativoFotografo: number, nomeModello: string): Promise<boolean>{
        let esito = false;

        try {
            const params = `${identificativoFotografo}/${nomeModello}`;
            const response = await Rest.Delete<boolean>(`${Config.PrivatePath}/VincoliModelli/${params}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore durante l'inserimento del vincolo sul modello", e);
        }

        return esito;
    }
}
