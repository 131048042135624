import React, {Fragment} from "react";

import InformazioniBase from "../Sezioni/InformazioniBase/InformazioniBase";
import Rilegatura from "../Sezioni/Rilegatura/Rilegatura";
import Copertina from "../Sezioni/Copertina/Copertina";
import Custodia from "../Sezioni/Custodia/Custodia";
import Accessori from "../Sezioni/Accessori/Accessori";
import Riepilogo from "../Sezioni/Riepilogo/Riepilogo";
import ConfiguratoreTopSection from "../Commons/ConfiguratoreTopSection";
import StorageManager, {StorageManagerContext} from "../Storage/StorageManager";
import SwitchContainer from "../../../../Layout/SwitchContainer/SwitchContainer";
import RiepilogoConfiguratoreTici from "../Sezioni/Riepilogo/RiepilogoConfiguratoreTici";
import FotolibroMenu from "./FotolibroMenu";
import LocalStorageService from "../../../../Servicies/LocalStorageService";
import {CoordinatoData} from "../Sezioni/Riepilogo/SezioneCoordinato";
import {ConfigurazioneFotografoModel, FotolibroInitializer, FotolibroModel, TipoFotolibroType} from "tici_commons";
import TopLevelComponentStorage from "../../../../Core/Arch/TopLevelComponentStorage";
import UserService from "../../../../Servicies/UserService";

export interface FotolibroContextInterface extends FotolibroModel{
    coordinato: CoordinatoData
}

export const FotolibroContext = React.createContext<FotolibroContextInterface>(
    {
        ...FotolibroInitializer(),
        coordinato: undefined
    }
);
export const CoordinatoContext = React.createContext<CoordinatoData>(undefined);

export interface FotolibroState extends FotolibroModel{
    currentSection: number
    coordinatoData: CoordinatoData
    datBase64: string
    isSuperUser: boolean
}

export interface FotolibroProps {
    tipoFotolibro: TipoFotolibroType
    modalitaConfiguratore?: boolean
}

export default class Fotolibro extends ConfiguratoreTopSection<FotolibroProps, FotolibroState> {
    private _configurazioneSalvataConfiguratoreTici: number;

    constructor(props: Readonly<FotolibroProps> | FotolibroProps) {
        super(props);
        const configurazioneFotolibro = FotolibroInitializer();
        configurazioneFotolibro.informazioniBase.tipoFotolibro = this.props.tipoFotolibro;
        this.state = {
            currentSection: 0,
            coordinatoData: undefined,
            datBase64: undefined,
            isSuperUser: false,
            ...configurazioneFotolibro
        }
    }

    public async componentDidMount() {
        const configurazioneTiciAttuale = LocalStorageService.ConfigurazioneTiciAttuale;
        if(configurazioneTiciAttuale !== undefined) {
            this._configurazioneSalvataConfiguratoreTici = configurazioneTiciAttuale
            LocalStorageService.ConfigurazioneTiciAttuale = undefined;
        }
        LocalStorageService.CustodiaImmagineGenerata = undefined;
        LocalStorageService.CopertinaImmagineGenerata = undefined;
        LocalStorageService.SezioniObbligatorieCustodiaRidotti = [];
        LocalStorageService.SetSezioniObblicatorieCopertinaRidottiModelloDefault();
        LocalStorageService.SezioniObbligatorieCustodia = [];
        LocalStorageService.SezioniObbligatorieCopertina = [];
        this._controllaConfigurazioneSalvaPerDopo();
        await this._callServicies();
    }

    public componentDidUpdate(prevProps: Readonly<FotolibroProps>, prevState: Readonly<FotolibroState>, snapshot?: any) {
        //console.log("Update");
    }

    private async _callServicies(){
        const isSuperUser = await UserService.IsSuperUser();
        this.setState({
            isSuperUser
        })
    }

    /**
     * Controlla la configurazione Salva per dopo e domanda all'utente se la vuole caricare
     * @private
     */
    private _controllaConfigurazioneSalvaPerDopo(){
        const salvaPerDopo = LocalStorageService.SalvaPerDopoData;
        if(salvaPerDopo && salvaPerDopo.informazioniBase && salvaPerDopo.informazioniBase.tipoFotolibro === this.props.tipoFotolibro){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                'Salva per dopo trovata',
                "Abbiamo trovato una configurazione salva per dopo per questa tipologia di ordine. Caricarla?",
                'DoubleButton',
                () => {
                    this.setState(currentState => {
                        const loadedState: FotolibroModel = {
                            informazioniBase: {...currentState.informazioniBase, ...salvaPerDopo.informazioniBase, tipoFotolibro: this.props.tipoFotolibro},
                            rilegatura: {...currentState.rilegatura, ...salvaPerDopo.rilegatura},
                            copertina: {...currentState.copertina, ...salvaPerDopo.copertina},
                            custodia: {...currentState.custodia, ...salvaPerDopo.custodia},
                            accessori: {...currentState.accessori, ...salvaPerDopo.accessori}
                        }
                        return {
                            ...currentState,
                            ...loadedState,
                            currentSection: 0,
                            fileDatBase64: undefined
                        }
                    }, () => LocalStorageService.SalvaPerDopoData = undefined);
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                },
                () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
            )
        }
    }

    /**
     * Esegue il caricamento di una configurazione sullo stato attuale del fotolibro
     * @param configurazione Configurazione da caricare
     * @private
     */
    private _caricaConfigurazione(configurazione: ConfigurazioneFotografoModel){
        if(this.props.tipoFotolibro !== 'accessori'){
            this.setState(currentState => {
                const loadedState: FotolibroModel = {
                    informazioniBase: {...currentState.informazioniBase, ...(configurazione.data as unknown as FotolibroModel).informazioniBase, tipoFotolibro: this.props.tipoFotolibro},
                    rilegatura: {...currentState.rilegatura, ...(configurazione.data as unknown as FotolibroModel).rilegatura},
                    copertina: {...currentState.copertina, ...(configurazione.data as unknown as FotolibroModel).copertina},
                    custodia: {...currentState.custodia, ...(configurazione.data as unknown as FotolibroModel).custodia},
                    accessori: {...currentState.accessori, ...(configurazione.data as unknown as FotolibroModel).accessori}
                }
                return {
                    ...currentState,
                    ...loadedState,
                    currentSection: 0,
                    coordinatoData: configurazione.informazioniCoordinato as unknown as CoordinatoData
                }
            });
        }
    }

    public render() {
        return (
            <Fragment>
                <FotolibroMenu
                    tipoFotolibro={this.props.tipoFotolibro}
                    modalitaConfiguratore={this.props.modalitaConfiguratore}
                    currentSection={this.state.currentSection}
                    setSection={section => this.setState({currentSection: section})}
                    nomeProgetto={this.state.informazioniBase.nomeProgetto}/>
                <FotolibroContext.Provider value={{...this.state, coordinato: this.state.coordinatoData}}>
                    <CoordinatoContext.Provider value={this.state.coordinatoData}>
                        <StorageManager>
                            <StorageManagerContext.Consumer>{
                                storage => (
                                    <SwitchContainer caseNumber={this.state.currentSection}>
                                        <InformazioniBase
                                            data={this.state.informazioniBase}
                                            storage={storage}
                                            modalitaConfiguratore={this.props.modalitaConfiguratore}
                                            loadConfigurazione={this._caricaConfigurazione.bind(this)}
                                            SetParentState={(p) => this.SubStateChangerCallback("informazioniBase", p)}
                                            resetInformazioniCopertinaCustodia={() => {
                                                this.SubStateChangerCallback("copertina", {modelloCopertina: "", sezioniCopertina: []});
                                                this.SubStateChangerCallback("custodia", {modelloCustodia: "", sezioniCustodia: []});
                                            }}
                                            resetInformazioniDorsoCopertina={() => this.SubStateChangerCallback("copertina", {dorsoCopertina: 'Standard'})}
                                            fileDatCaricato={!!this.state.datBase64}
                                            caricaFileDat={datBase64 => this.setState({datBase64: datBase64})}/>
                                        <Rilegatura
                                            data={this.state.rilegatura}
                                            storage={storage}
                                            modalitaConfiguratore={this.props.modalitaConfiguratore}
                                            SetParentState={(p) => this.SubStateChangerCallback("rilegatura", p)}
                                            stampaFlag={this.state.informazioniBase.stampaFlag}/>
                                        <Copertina
                                            data={this.state.copertina}
                                            storage={storage}
                                            SetParentState={(p) => this.SubStateChangerCallback("copertina", p)}
                                            orientamentoAlbumPrincipale={this.state.informazioniBase.orientamentoAlbum}
                                            formatoAlbumPrincipale={this.state.informazioniBase.formatoAlbum}
                                            albumRidottoFlag={this.state.informazioniBase.albumRidottoFlag}
                                            albumRidottoQuantita={this.state.informazioniBase.albumRidottoQuantita}
                                            albumPocketFlag={this.state.informazioniBase.albumPocketFlag}
                                            albumPocketQuantita={this.state.informazioniBase.albumPocketQuantita}/>
                                        <Custodia
                                            data={this.state.custodia}
                                            storage={storage}
                                            SetParentState={(p) => this.SubStateChangerCallback("custodia", p)}
                                            formatoAlbumPrincipale={this.state.informazioniBase.formatoAlbum}
                                            albumRidottoFlag={this.state.informazioniBase.albumRidottoFlag}
                                            albumRidottoQuantita={this.state.informazioniBase.albumRidottoQuantita}
                                            tipoFotolibro={this.state.informazioniBase.tipoFotolibro}
                                            custodiaFlag={this.state.informazioniBase.custodiaFlag}/>
                                        <Accessori
                                            storage={storage}
                                            SetParentState={(p) => this.SubStateChangerCallback("accessori", p)}
                                            data={this.state.accessori}/>
                                        <Riepilogo
                                            storage={storage}
                                            fotolibro={this.state}
                                            SetParentState={(p) => this.SubStateChangerCallback("informazioniBase", p)}
                                            data={this.state.informazioniBase}
                                            datBase64={this.state.datBase64}/>
                                        <RiepilogoConfiguratoreTici
                                            storage={storage}
                                            fotolibro={this.state}
                                            SetParentState={(p) => this.SubStateChangerCallback("informazioniBase", p)}
                                            data={this.state.informazioniBase}
                                            idConfigurazioneAperta={this._configurazioneSalvataConfiguratoreTici}
                                            datiCoordinatoAperto={this.state.coordinatoData}/>
                                    </SwitchContainer>
                                )
                            }</StorageManagerContext.Consumer>
                        </StorageManager>
                        {/*
                            <IfContainer condition={this.state.isSuperUser}>
                                <FooterStickyPagamento fotolibroData={this.state}/>
                            </IfContainer>
                        */}
                    </CoordinatoContext.Provider>
                </FotolibroContext.Provider>
            </Fragment>
        );
    }
}
