import React, {Fragment} from "react";
import {createPortal} from "react-dom";

export const StorageDataMapper = {
    FotoUvFronte: 'FotoUvFronte',
    FotoUvRetro: 'FotoUvRetro',
    FotoTela: 'FotoTela',
    StampaLegno: 'StampaLegno',
    UvRidotto: 'UvRidotto',
    UvPocket: 'UvPocket',
    StampeFogli: 'StampeFogli',
    StampeRilegatura: 'StampeRilegatura',
    ImmagineFoderaInizio: "ImmagineFoderaInizio",
    ImmagineFoderaFine: "ImmagineFoderaFine",
    LogoFodera: "LogoFodera",
    ImmagineCofanettoRidotto: "ImmagineCofanettoRidotto",
    UvCofanettoRidotto: "UvCofanettoRidotto",
    UvChiavettaUsb: "UvChiavettaUsb",
    PannelliPlex: "PannelliPlex",
    PannelliAlufoam: "PannelliAlufoam",
    StampeAccessori: "StampeAccessori"
}

export interface StorageDataRendererProps {
    storageData?: StorageData;
    className?: string
}

export interface StorageDataRendererState {
    base64Data: string
}

export class StorageDataRenderer extends React.Component<StorageDataRendererProps, StorageDataRendererState>{
    constructor(props: Readonly<StorageDataRendererProps> | StorageDataRendererProps) {
        super(props);
        this.state = {
            base64Data: ""
        }
    }

    /**
     * Carica le informazioni del base64 dallo storage data
     * @private
     */
    private _loadBase64() {
        if(this.props.storageData){
            this.props.storageData.base64().then(base64 => {
                this.setState({base64Data: base64});
            })
        }
    }

    componentDidMount() {
        this._loadBase64();
    }

    componentDidUpdate(prevProps: Readonly<StorageDataRendererProps>, prevState: Readonly<StorageDataRendererState>, snapshot?: any) {
        if(prevProps.storageData !== this.props.storageData)
            this._loadBase64();
    }

    public render() {
        return (
            this.props.storageData && <img alt={this.props.storageData.name} className={this.props.className} src={this.state.base64Data}/>
        )
    }
}

export class StorageData{
    private _targetMiniaturePixels = 4000000;
    private _category: string;
    private _name: string;
    private _base64Data: string;
    private _file: File;
    private _memorizedUrl: string;

    constructor(category: string, name: string, file?: File, base64Data?: string, doneCallback?: () => void, createMiniature = true) {
        this._category = category;
        this._name = name;
        this._file = file;
        this._base64Data = base64Data;

        createMiniature && this._generateMiniature(miniature => {
            this._memorizedUrl = miniature;
            doneCallback && doneCallback();
        });
    }

    public static StorageDataWithUrl(category: string, name: string, url: string){
        const storageData = new StorageData(category, name);
        storageData._memorizedUrl = url;
        return storageData;
    }

    /**
     * Genera la versione miniaturizzata dell'immagine
     * @param callback Callback chiamata al termine della generazione della miniatura
     * @private
     */
    private _generateMiniature(callback: (miniature: string) => void){
        if(this._file || this._base64Data){
            const image = document.createElement('img');
            image.addEventListener("load", () => {
                let width = image.width;
                let height = image.height;
                const moltFactor = this._targetMiniaturePixels / (width * height);

                const canvas = document.createElement('canvas');
                canvas.width = Math.floor(width * moltFactor);
                canvas.height = Math.floor(height * moltFactor);
                const ctx = canvas.getContext('2d');

                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                callback && callback(canvas.toDataURL());
            });
            image.src = this._file ? URL.createObjectURL(this._file) : this._base64Data;
        }
    }

    /**
     * Restituisce il base64 del dato
     */
    public base64(): Promise<string>{
        return new Promise<string>(resolve => {
            if(this._base64Data)
                resolve(this._base64Data);
            else if(this._file){
                const fileReader = new FileReader();
                fileReader.addEventListener("loadend", () => {
                    const base64 = fileReader.result as string;
                    resolve(base64);
                });
                fileReader.addEventListener("error", () => {
                    resolve("");
                })
                fileReader.readAsDataURL(this._file);
            }else resolve("");
        });
    }

    /**
     * Restituisce l'url dello StorageData
     */
    public url(): string{
        return this._memorizedUrl;
    }

    get file(): File {
        return this._file;
    }

    set file(value: File) {
        this._file = value;
    }

    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
}

export interface StorageManagerStatus{
    StatusCurrentUpdate: number;
    GetStorage: () => StorageData[];
    SetStorageData: (category: string, name: string, file: File, base64Data?: string) => Promise<StorageData>;
    DeleteStorageData: (category: string, name?: string) => void;
    GetStorageData: (category: string, name?: string) => StorageData[];
}

export const StorageManagerContext = React.createContext<StorageManagerStatus>(undefined);

export default class StorageManager extends React.Component<{children: any}, StorageManagerStatus>{
    private _showInfoWindow = false;
    private _storage: StorageData[] = [];   //Storage effettivo dei dati

    private _infoWindow: Window;

    constructor(props: Readonly<{children: any}> | {children: any}) {
        super(props);
        this.state = {
            StatusCurrentUpdate: 0,
            GetStorage: this._getStorage.bind(this),
            SetStorageData: this._setStorageData.bind(this),
            DeleteStorageData: this._deleteStorageData.bind(this),
            GetStorageData: this._getStorageData.bind(this),
        }
    }

    public componentDidMount() {
        if(this._showInfoWindow) {
            this._infoWindow = window.open('', '_blank');
        }
    }

    /**
     * Aggiunge il dato all'interno dello storage
     * @param category Identificativo del gruppo
     * @param name Nome del dato inserito all'interno dello storage
     * @param file Dato associato all'interno dello storage
     * @param base64Data Dati base64 da associare
     * @protected
     */
    private _setStorageData(category: string, name: string, file?: File, base64Data?: string): Promise<StorageData> {
        return new Promise(resolve => {
            this._deleteStorageData(category, name);
            const storageData = new StorageData(category, name, file, base64Data, () => {
                this.setState(prevState => ({StatusCurrentUpdate: prevState.StatusCurrentUpdate + 1}), () => resolve(storageData));
            });
            this._storage.push(storageData);
        });
    }

    /**
     * Cancella un elemento all'interno dello storage
     * @param category Identificativo del gruppo da cancellare
     * @param name Nome dell'elemento da cancellare
     * @protected
     */
    private _deleteStorageData(category: string, name?: string){
        this._storage = this._storage.filter(data => {
            let esito = true;

            if(data.category === category){
                if(name) {
                    if(data.name === name)
                        esito = false;
                } else {
                    esito = false;
                }
            }

            return esito;
        });
        
        this.setState(prevState => ({StatusCurrentUpdate: prevState.StatusCurrentUpdate + 1}));
    }

    /**
     * Restituisce il dato all'interno dello storage
     * @param category Identificativo del dato da recuperare
     * @param name Nome dell'elemento da recuperare
     * @protected
     */
    private _getStorageData(category: string, name?: string): StorageData[]{
        let esito: StorageData[] = [];

        for(let data of this._storage){
            if(data.category === category){
                if(name) {
                    if (data.name === name)
                        esito.push(data);
                }else esito.push(data);
            }
        }

        return esito;
    }

    /**
     * Restituisce l'intero storage
     * @private
     */
    private _getStorage(): StorageData[]{
        return this._storage;
    }

    public componentWillUnmount() {
        this._showInfoWindow && this._infoWindow && this._infoWindow.close();
    }

    public render() {
        return (
            <Fragment>
                <StorageManagerContext.Provider value={this.state}>
                    {this.props.children}
                </StorageManagerContext.Provider>
                {
                    this._showInfoWindow && this._infoWindow && createPortal(
                        <div style={{maxHeight: 600, overflow: 'auto'}}>
                            <ul>
                                {
                                    this.state.GetStorage()
                                        .map(storageData => ({category: storageData.category, name: storageData.name}))
                                        .map((data, index) => <li key={`li-${index}-${data.name}-${data.category}`}>{data.category}: {data.name}</li>)
                                }
                            </ul>
                        </div>,
                        this._infoWindow.document.body
                    )
                }
            </Fragment>
        );
    }
}
