import './HomePage.scss';
import React, {Fragment} from "react";
import Header from "../../../Primary/Header/Header";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import UserService from "../../../Servicies/UserService";
import {AmministratoreContextResponseModel, UserContextResponseModel} from "tici_commons";
import Submenu from "../../../Primary/Submenu/Submenu";
import NotificaProvider from "../../../Primary/NotificaIcon/NotificaIcon";
import ModalePubblicita from "../../../Primary/ModalePubblicita/ModalePubblicita";
import ModaleInformativa from "../../../Primary/ModaleInformativa/ModaleInformativa";
import BackgroundOrdineManager from "../../../Primary/BackgroundOrdineManager/BackgroundOrdineManager";
import ConfigurazioneGestore3DBridge from "../SuperUser/Bridge/ConfigurazioneGestore3DBridge";
import SessionAndAccessValidator, {Page} from "../../../Core/Arch/SessionAndAccessValidator";
import _404 from "../../404";
import Default from "../../Default";
import Fotolibro from "../Configuratore/Fotolibro/Fotolibro";
import ConfigurazioniFotografo from "../ConfigurazioniFotografo/ConfigurazioniFotografo";
import Ordini from "../Ordini/Ordini";
import ModificaUtente from "../ModificaUtente/ModificaUtente";
import AccettazioneFotografi from "../AccettazioneFotografi/AccettazioneFotografi";
import Agenti from "../Agenti/Agenti";
import Impersonificazione from "../Impersonificazione/Impersonificazione";
import GestoreMateriali from "../SuperUser/GestoreMateriali/GestoreMateriali";
import GestoreModelli3D from "../SuperUser/GestoreModelli3D/GestoreModelli3D";
import AnalyticsRotte from "../Analytics/AnalyticsRotte";
import NuovoOrdine from "../NuovoOrdine/NuovoOrdine";
import SuperUser from "../SuperUser";
import Notifiche from "../Notifiche/Notifiche";
import Chat from "../Chat/Chat";
import ConfiguratoreModelli from "../SuperUser/ConfiguratoreModelli/ConfiguratoreModelli";
import PaginaDownload from "../PaginaDownload/PaginaDownload";
import AmministrazioneService from "../../../Servicies/AmministrazioneService";
import Vincoli from "../SuperUser/Vincoli/Vincoli";

export interface HomePageState {
    userContext: UserContextResponseModel,
    amministrazioneContext: AmministratoreContextResponseModel,
    superUser: boolean
}

export default class HomePage extends React.Component<{}, HomePageState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            userContext: undefined,
            amministrazioneContext: undefined,
            superUser: false
        }

        this._callServicies();
    }

    /**
     * Chiama i servizi utili per il menu
     * @private
     */
    private async _callServicies(){
        try {
            const userContext = await UserService.GetUserContext();
            const superUser = await UserService.IsSuperUser();
            let amministrazioneContext: AmministratoreContextResponseModel;
            if(userContext?.tipoUtente === 'amministrazione') {
                amministrazioneContext = await AmministrazioneService.GetAmministrazioneContext();
            }
            this.setState({userContext, amministrazioneContext, superUser});
        }catch (e){
            console.error("Errore nella chiamata dei servizi", String(e));
        }
    }

    public render() {
        return (
            <Fragment>
                <ModalePubblicita/>
                <ModaleInformativa/>
                <NotificaProvider>
                    <div className={"HomePageBody"}>
                        <Header utente={this.state.userContext} amministrazioneContext={this.state.amministrazioneContext}/>
                        <Submenu utente={this.state.userContext} amministrazioneContext={this.state.amministrazioneContext} superUser={this.state.superUser}>
                            <BackgroundOrdineManager>
                                <div className={"HomePageInternalBody"}>
                                    <ConfigurazioneGestore3DBridge>
                                        <SessionAndAccessValidator
                                            level={1}
                                            notFountPage={<Page pageName={"404"} publicPage={true} pageComponent={<_404/>}/>}>
                                            <Page pageName={""} publicPage={false} pageComponent={<Default/>}/>
                                            <Page pageName={"nuovo_ordine"} publicPage={false} pageComponent={<NuovoOrdine/>}/>
                                            <Page pageName={"fotolibro_configurazione_libera"} publicPage={false} pageComponent={<Fotolibro key={"configurazione_libera"} tipoFotolibro={"libero"}/>}/>
                                            <Page pageName={"album_tradizionale"} publicPage={false} pageComponent={<Fotolibro key={"tradizionale"} tipoFotolibro={"tradizionale"}/>}/>
                                            <Page pageName={"album_blocco_book"} publicPage={false} pageComponent={<Fotolibro key={"blocco_book"} tipoFotolibro={"blocco_book"}/>}/>
                                            <Page pageName={"coordinato_wedding"} publicPage={false} pageComponent={<Fotolibro key={"coordinato_wedding"} tipoFotolibro={"wedding_project"}/>}/>
                                            <Page pageName={"coordinato_art"} publicPage={false} pageComponent={<Fotolibro key={"coordinato_art"} tipoFotolibro={"art_project"}/>}/>
                                            <Page pageName={"coordinato_junior"} publicPage={false} pageComponent={<Fotolibro key={"coordinato_junior"} tipoFotolibro={"junior_project"}/>}/>
                                            <Page pageName={"accessori"} publicPage={false} pageComponent={<Fotolibro key={"accessori"} tipoFotolibro={"accessori"}/>}/>
                                            <Page pageName={"configurazioni_fotografo"} publicPage={false} pageComponent={<ConfigurazioniFotografo/>}/>
                                            <Page pageName={"configuratore_tici"} publicPage={false} pageComponent={<Fotolibro key={"configuratore"} tipoFotolibro={"libero"} modalitaConfiguratore={true}/>}/>
                                            <Page pageName={"ordini"} publicPage={false} pageComponent={<Ordini amministrazioneContext={this.state.amministrazioneContext}/>}/>
                                            <Page pageName={"account"} publicPage={false} pageComponent={<ModificaUtente/>}/>
                                            <Page pageName={"studi_fotografici"} publicPage={false} pageComponent={<AccettazioneFotografi/>}/>
                                            <Page pageName={"agenti"} publicPage={false} pageComponent={<Agenti/>}/>
                                            <Page pageName={"notifiche"} publicPage={false} pageComponent={<Notifiche/>}/>
                                            <Page pageName={"chat"} publicPage={false} pageComponent={<Chat/>}/>
                                            <Page pageName={"impersonificazione"} publicPage={false} pageComponent={<Impersonificazione/>}/>
                                            <Page pageName={"super_user"} publicPage={false} pageComponent={<SuperUser/>}/>
                                            <Page pageName={"gestione_materiali"} publicPage={false} pageComponent={<GestoreMateriali/>}/>
                                            <Page pageName={"gestione_modelli_3d"} publicPage={false} pageComponent={<GestoreModelli3D/>}/>
                                            <Page pageName={"configuratore_modelli_3d"} publicPage={false} pageComponent={<ConfiguratoreModelli/>}/>
                                            <Page pageName={"analytics_rotte"} publicPage={false} pageComponent={<AnalyticsRotte/>}/>
                                            <Page pageName={"vincoli"} publicPage={false} pageComponent={<Vincoli/>}/>
                                            <Page pageName={"pagina_download"} publicPage={false} pageComponent={<PaginaDownload/>}/>
                                        </SessionAndAccessValidator>
                                    </ConfigurazioneGestore3DBridge>
                                </div>
                            </BackgroundOrdineManager>
                        </Submenu>
                        <ResponsiveLabel
                            content={"Servizio offerto da Cartotecnica tici s.r.l - tutti i diritti sono riservati - nessuno userà i tuoi dati senza permesso. Servizio clienti al numero +39 376 091 6987"}
                            type={"xsmall"}
                            alignment={"center"}
                            italic={true}
                            labelType={"footerHp"}/>
                    </div>
                </NotificaProvider>
            </Fragment>
        );
    }
}
